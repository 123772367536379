import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const ToiletIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M46.105 18.814a.88.88 0 0 1-.878-.88V15.29h-3.904a.88.88 0 0 1 0-1.758h4.782a.88.88 0 0 1 .88.879v3.525a.879.879 0 0 1-.88.879Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M43.518 34.719c.072-.485.109-.977.109-1.473a3.688 3.688 0 0 0-1.416-2.917V14.136a6.895 6.895 0 0 0-6.902-6.9H24.333a6.894 6.894 0 0 0-6.894 6.558c-.004.114-.009.229-.009.342v16.232a3.707 3.707 0 0 0-1.415 2.912v.109a10.16 10.16 0 0 0 .103 1.428 10.19 10.19 0 0 0 3.791 6.618 8.454 8.454 0 0 1 3.227 6.668v6.782c0 .485.394.879.879.879h11.24a.88.88 0 0 0 .88-.88v-6.636a8.552 8.552 0 0 1 3.398-6.801 10.32 10.32 0 0 0 3.985-6.728ZM17.773 33.39v-.108a1.96 1.96 0 0 1 1.952-1.956l20.183-.035h.003a1.94 1.94 0 0 1 1.382.571c.37.37.574.862.574 1.384 0 .172-.006.343-.016.513H17.784a7.84 7.84 0 0 1-.01-.369Zm6.56-24.397h10.976a5.136 5.136 0 0 1 5.067 4.253H19.265a5.139 5.139 0 0 1 5.068-4.253Zm-5.146 6.011h21.265V29.57a3.745 3.745 0 0 0-.547-.038l-20.183.035a3.76 3.76 0 0 0-.533.04V15.004h-.002Zm19.281 25.042a10.312 10.312 0 0 0-4.095 8.202v5.758h-9.48v-5.903c0-3.156-1.42-6.09-3.893-8.047a8.449 8.449 0 0 1-2.957-4.541h23.51a8.548 8.548 0 0 1-3.085 4.531Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default ToiletIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const DormRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M9.195 53.913H4.214a.88.88 0 0 1-.88-.88V22.497a.89.89 0 0 1 .162-.508 3.885 3.885 0 0 1 3.208-1.658c1.292 0 2.462.605 3.209 1.658a.877.877 0 0 1 .161.509v17.85a.88.88 0 0 1-1.758 0V22.801a2.143 2.143 0 0 0-1.612-.715c-.627 0-1.201.258-1.611.715v29.352h3.223v-5.347a.88.88 0 0 1 1.758 0v6.227a.88.88 0 0 1-.88.88ZM58.786 53.913h-4.983a.88.88 0 0 1-.879-.879v-6.227a.88.88 0 0 1 1.758 0v5.348h3.225V40.348a.88.88 0 0 1 1.758 0v12.686a.88.88 0 0 1-.879.88Z"
      fill={color || "#111928"}
    />
    <path
      d="M58.786 47.685H4.214a.88.88 0 0 1-.88-.879v-6.46a.88.88 0 0 1 .88-.879h54.57a.88.88 0 0 1 .88.88v6.459a.877.877 0 0 1-.878.88ZM5.093 45.927h52.813v-4.702H5.093v4.702Z"
      fill={color || "#111928"}
    />
    <path
      d="M58.786 41.2h-33.23a.88.88 0 0 1-.88-.879V27.136a.88.88 0 0 1 .88-.879h21.06c7.195 0 13.05 5.855 13.05 13.05v1.016a.879.879 0 0 1-.88.878Zm-32.351-1.758h31.472v-.136c0-6.227-5.065-11.291-11.292-11.291h-20.18v11.427Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M18.418 36.985h-2.186a3.968 3.968 0 0 1-3.963-3.963v-.468a3.968 3.968 0 0 1 3.963-3.963h4.347c.993 0 1.802.81 1.802 1.802v2.629a3.967 3.967 0 0 1-3.963 3.963Zm-2.186-6.636a2.207 2.207 0 0 0-2.205 2.205v.468c0 1.216.989 2.205 2.205 2.205h2.186a2.207 2.207 0 0 0 2.205-2.205v-2.629c0-.022-.022-.044-.044-.044h-4.347Z"
      fill={color || "#111928"}
    />
    <path
      d="M30.83 41.2a.88.88 0 0 1-.879-.879V27.136a.88.88 0 0 1 1.758 0v13.186a.879.879 0 0 1-.879.88Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M30.93 21.667h-5.045a.878.878 0 0 1-.62-1.5l3.543-3.544h-2.923a.88.88 0 0 1 0-1.758h5.045a.878.878 0 0 1 .621 1.5l-3.544 3.544h2.923a.88.88 0 0 1 0 1.758ZM40.587 15.89h-5.045a.878.878 0 0 1-.621-1.5l3.544-3.544h-2.923a.88.88 0 0 1 0-1.758h5.044a.878.878 0 0 1 .622 1.5l-3.544 3.544h2.922a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default DormRoomIcon;

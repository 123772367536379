import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const CycleStudio = ({ height = 63, width = 63 }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 45 45">
    <path
      d="M17.55 26.618a.628.628 0 0 1-.584-.398l-3.132-7.935a.627.627 0 1 1 1.168-.46l3.132 7.935a.627.627 0 0 1-.584.858ZM20.527 34.16a.628.628 0 0 1-.584-.398l-.833-2.111a.627.627 0 1 1 1.168-.46l.833 2.11a.627.627 0 0 1-.584.858Z"
      fill="#111928"
    />
    <path
      d="M20.527 34.16H11.02a.628.628 0 0 1-.549-.93l5.6-10.196a.628.628 0 0 1 .468-.32l2.764-.366a.628.628 0 1 1 .165 1.245l-2.449.325-4.935 8.987h8.154L29.19 22.3l-5.197.691a.628.628 0 1 1-.165-1.245l6.807-.905a.628.628 0 0 1 .562 1.028L21.01 33.936a.634.634 0 0 1-.482.223ZM30.774 10.459a1.243 1.243 0 0 1-1.065-.59l-.908-1.46c-.381-.612-.5-1.34-.336-2.046a2.688 2.688 0 0 1 1.206-1.687l1.194-.743a2.714 2.714 0 0 1 3.732.87l.137.22a2.714 2.714 0 0 1-.87 3.733l-2.432 1.514c-.2.125-.427.189-.657.189Zm-.907-2.714.909 1.46L33.2 7.69c.682-.424.891-1.322.467-2.003l-.137-.221A1.455 1.455 0 0 0 31.528 5l-1.194.743a1.446 1.446 0 0 0-.646.906c-.089.38-.025.77.18 1.097Z"
      fill="#111928"
    />
    <path
      d="M11.018 41.473c-4.37 0-7.927-3.556-7.927-7.927 0-4.371 3.556-7.927 7.927-7.927 4.372 0 7.928 3.556 7.928 7.927 0 4.371-3.556 7.927-7.928 7.927Zm0-14.598a6.68 6.68 0 0 0-6.671 6.671 6.68 6.68 0 0 0 6.671 6.672 6.68 6.68 0 0 0 6.672-6.672 6.679 6.679 0 0 0-6.672-6.671Z"
      fill="#111928"
    />
    <path
      d="M11.02 38.736a5.196 5.196 0 0 1-5.19-5.19.628.628 0 0 1 1.256 0 3.938 3.938 0 0 0 3.934 3.934.628.628 0 0 1 0 1.256ZM34.985 38.736a5.196 5.196 0 0 1-5.19-5.19.628.628 0 0 1 1.256 0 3.938 3.938 0 0 0 3.934 3.934.628.628 0 0 1 0 1.256Z"
      fill="#111928"
    />
    <path
      d="M34.981 41.473c-4.37 0-7.927-3.556-7.927-7.927 0-4.371 3.556-7.927 7.927-7.927 4.372 0 7.928 3.556 7.928 7.927 0 4.371-3.557 7.927-7.928 7.927Zm0-14.598a6.68 6.68 0 0 0-6.671 6.671 6.68 6.68 0 0 0 6.671 6.672 6.68 6.68 0 0 0 6.672-6.672 6.68 6.68 0 0 0-6.672-6.671ZM33.605 22.744a.628.628 0 0 1-.515-.986c.024-.035.569-.853.254-1.53-.659-.003-2.2 0-3.284.002h-.001a.628.628 0 0 1-.001-1.255s.905-.003 1.811-.004h1.248l.414.002c.231 0 .449.002.637.229 1.199 1.442.007 3.199-.045 3.272a.635.635 0 0 1-.518.27Z"
      fill="#111928"
    />
    <path
      d="M34.981 34.174a.629.629 0 0 1-.592-.419l-4.924-13.944a.63.63 0 0 1 1.185-.419l4.923 13.945a.63.63 0 0 1-.592.837ZM15.95 18.683h-4.047a.628.628 0 0 1 0-1.256h4.047a.628.628 0 0 1 0 1.256Z"
      fill="#111928"
    />
    <path
      d="M20.15 32.44a.632.632 0 0 1-.41-.15L15.71 28.82a.627.627 0 0 1-.127-.802l3.29-5.42-3.324-4.8a.556.556 0 0 1-.045-.077c-.137-.276-1.305-2.75.002-5a4.81 4.81 0 0 1 1.772-1.737.627.627 0 1 1 .625 1.09c-.558.32-1.011.762-1.312 1.279-.933 1.606-.146 3.427.017 3.77l3.529 5.096a.63.63 0 0 1 .02.683l-3.224 5.314 3.175 2.734c.148-.52-.09-1.563-.332-2.165a.625.625 0 0 1 .055-.574l3.035-4.693a.693.693 0 0 1 .052-.07 1.49 1.49 0 0 0-.081-2.033.722.722 0 0 1-.07-.08l-2.926-4.065a.628.628 0 1 1 1.019-.735l2.898 4.024a2.749 2.749 0 0 1 .141 3.675l-2.842 4.396c.29.86.871 3.088-.654 3.758a.64.64 0 0 1-.255.052Z"
      fill="#111928"
    />
    <path
      d="M20.334 17.532a.628.628 0 0 1-.56-.342l-2.746-5.374a.631.631 0 0 1 .295-.857l6.335-3.24.03-.014c.95-.425 1.703-.39 1.868-.377a3.664 3.664 0 0 1 3.272 2.652.628.628 0 1 1-1.209.34 2.398 2.398 0 0 0-2.152-1.74l-.017-.002c-.023-.002-.533-.045-1.236.266l-5.784 2.958 2.176 4.256 5.134-2.625a.627.627 0 1 1 .571 1.118l-5.68 2.904-.022.01-.014.006a.573.573 0 0 1-.26.061Z"
      fill="#111928"
    />
    <path
      d="M30.996 21.38c-.524 0-1.047-.168-1.564-.502a.613.613 0 0 1-.16-.149l-3.14-4.144a.646.646 0 0 1-.106-.213l-1.595-5.845a.628.628 0 1 1 1.211-.33l1.564 5.727 2.996 3.955c.894.523 1.566.082 1.925-.286l-3.249-4.28a.624.624 0 0 1-.11-.232l-1.15-4.78a.628.628 0 0 1 1.22-.294l1.121 4.653 3.464 4.562c.16.21.17.495.03.717-.074.114-.748 1.127-1.923 1.384a2.661 2.661 0 0 1-.534.056Z"
      fill="#111928"
    />
  </svg>
);

export default CycleStudio;

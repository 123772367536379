import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const BoxingStudio = ({ height = 63, width = 63 }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 45 45">
    <path
      d="M28.364 37.369H17.002a.628.628 0 0 1 0-1.256h10.734V33.66H17.002a.628.628 0 0 1 0-1.256h11.362c.346 0 .627.282.627.628v3.71a.628.628 0 0 1-.627.628ZM25.016 24.587c-3.563 0-6.354-1.718-6.354-3.91 0-2.19 2.791-3.908 6.354-3.908 3.562 0 6.353 1.717 6.353 3.909 0 2.191-2.79 3.909-6.353 3.909Zm0-6.562c-2.763 0-5.098 1.215-5.098 2.653s2.334 2.653 5.098 2.653 5.097-1.215 5.097-2.653-2.334-2.653-5.097-2.653Z"
      fill="#111928"
    />
    <path
      d="M36.01 11.725a7.369 7.369 0 0 0-7.34-6.61h-7.307a7.369 7.369 0 0 0-7.34 6.61c-.048.45-.093.913-.133 1.383-.481-.262-1.107-.491-1.81-.474-.925.024-1.768.453-2.503 1.277-.754.844-1.084 2.415-.906 4.31.131 1.396.598 3.603 2.077 6.197 1.356 2.382 3.25 4.534 5.626 6.4v8.439c0 .346.281.628.628.628H33.03a.628.628 0 0 0 .628-.628v-8.55c3.106-4.634 2.997-12.86 2.352-18.982ZM21.364 6.37h7.305a6.115 6.115 0 0 1 6.092 5.487c.04.382.076.759.11 1.132a7.488 7.488 0 0 0-3.41-.722.628.628 0 1 0 .033 1.256 6.196 6.196 0 0 1 3.495.969c.062.923.102 1.819.12 2.685-.454-.258-1.052-.47-1.829-.514a.628.628 0 0 0-.073 1.254c1.173.067 1.721.639 1.907.89-.063 4.883-.885 8.702-2.418 11.081H17.338c-1.534-2.377-2.354-6.193-2.418-11.07.173-.237.721-.833 1.915-.902a.628.628 0 1 0-.073-1.253c-.782.044-1.383.258-1.838.52.017-.864.058-1.758.119-2.678a6.2 6.2 0 0 1 3.512-.981h.016a.628.628 0 0 0 .017-1.256A7.5 7.5 0 0 0 15.16 13c.033-.377.07-.757.11-1.142a6.118 6.118 0 0 1 6.093-5.488ZM9.92 18.105c-.17-1.798.195-2.913.591-3.357.494-.552 1.03-.841 1.59-.858.727-.022 1.367.398 1.68.644-.293 4.475-.156 9.53 1.231 13.405-4.229-4.25-4.965-8.49-5.092-9.834ZM17.63 38.63v-7.487h14.773v7.487H17.63Z"
      fill="#111928"
    />
  </svg>
);

export default BoxingStudio;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const BreakRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M31.5 57.016c-6.816 0-13.223-2.654-18.043-7.473-4.819-4.82-7.474-11.227-7.474-18.043 0-6.816 2.655-13.223 7.474-18.043 4.82-4.819 11.227-7.474 18.043-7.474 6.816 0 13.222 2.655 18.043 7.474 4.819 4.819 7.473 11.227 7.473 18.043 0 6.816-2.654 13.222-7.473 18.043-4.82 4.819-11.227 7.473-18.043 7.473Zm0-49.373a23.703 23.703 0 0 0-16.87 6.987A23.703 23.703 0 0 0 7.644 31.5a23.703 23.703 0 0 0 6.987 16.87 23.703 23.703 0 0 0 16.87 6.986 23.703 23.703 0 0 0 16.87-6.987 23.7 23.7 0 0 0 6.986-16.87 23.703 23.703 0 0 0-6.987-16.868A23.703 23.703 0 0 0 31.5 7.643Z"
      fill={color || "#111928"}
    />
    <path
      d="M31.5 31.856a.828.828 0 0 1-.83-.83V14.062a.829.829 0 1 1 1.659 0v16.964a.83.83 0 0 1-.83.831Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M31.5 32.33a.83.83 0 0 1-.586-1.417l8.823-8.823a.83.83 0 0 1 1.173 1.173l-8.824 8.823a.822.822 0 0 1-.586.243Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default BreakRoomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const ClassroomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M21.83 56.612a.884.884 0 0 1-.556-.19l-3.546-2.887a.884.884 0 0 1-.322-.688V48.04c0-.483.395-.879.879-.879h7.325c.484 0 .88.396.88.88v4.805a.895.895 0 0 1-.352.703l-3.766 2.886a.92.92 0 0 1-.542.176Zm-2.68-4.175 2.695 2.183 2.872-2.198v-3.487h-5.568v3.502Z"
      fill={color || "#111928"}
    />
    <path
      d="M36.115 48.92h-25.2a.882.882 0 0 1-.879-.879V8.146c0-.483.396-.879.88-.879h25.2c2.798 0 5.069 2.271 5.069 5.07v31.529c-.015 2.783-2.286 5.054-5.07 5.054ZM11.78 47.162H36.1a3.317 3.317 0 0 0 3.312-3.311V12.336a3.317 3.317 0 0 0-3.311-3.31H11.78v38.136ZM54.004 49.55h-6.168a.882.882 0 0 1-.879-.879c0-.483.396-.879.88-.879h6.167c.484 0 .88.396.88.88a.882.882 0 0 1-.88.878Z"
      fill={color || "#111928"}
    />
    <path
      d="M54.004 49.55a.882.882 0 0 1-.879-.879v-31.69h-4.41v31.69c0 .484-.395.88-.879.88a.882.882 0 0 1-.879-.88v-32.57c0-.483.396-.878.88-.878h6.167c.484 0 .88.395.88.879v32.57c0 .483-.396.878-.88.878Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M54.004 16.98h-6.168a.875.875 0 0 1-.718-.366.921.921 0 0 1-.117-.806l3.077-8.732a.872.872 0 0 1 .835-.586c.38 0 .703.235.835.586l3.077 8.732a.883.883 0 0 1-.117.806.867.867 0 0 1-.704.366Zm-4.922-1.758h3.677l-1.831-5.2-1.846 5.2ZM54.004 49.55h-6.168a.882.882 0 0 1-.879-.879c0-.483.396-.879.88-.879h6.167c.484 0 .88.396.88.88a.882.882 0 0 1-.88.878Z"
      fill={color || "#111928"}
    />
    <path
      d="M54.004 55.161h-6.168a.882.882 0 0 1-.879-.879v-5.626c0-.483.396-.879.88-.879.483 0 .878.396.878.88v4.746h4.41v-4.747c0-.483.396-.879.88-.879.483 0 .878.396.878.88v5.626a.872.872 0 0 1-.879.878ZM32.921 49.814a.882.882 0 0 1-.879-.88V7.268c0-.484.396-.88.88-.88.483 0 .878.396.878.88v41.668c0 .483-.395.879-.879.879ZM12.908 15.061H8.996a.882.882 0 0 1-.88-.879c0-.483.396-.879.88-.879h3.912c.483 0 .879.396.879.88 0 .483-.396.878-.88.878ZM12.908 22.02H8.996a.882.882 0 0 1-.88-.878c0-.484.396-.88.88-.88h3.912c.483 0 .879.396.879.88a.882.882 0 0 1-.88.879ZM12.908 28.98H8.996a.882.882 0 0 1-.88-.88c0-.483.396-.878.88-.878h3.912c.483 0 .879.395.879.879 0 .483-.396.879-.88.879ZM12.908 35.94H8.996a.882.882 0 0 1-.88-.88c0-.483.396-.879.88-.879h3.912c.483 0 .879.396.879.88 0 .483-.396.878-.88.878ZM12.908 42.884H8.996a.882.882 0 0 1-.88-.879c0-.483.396-.879.88-.879h3.912c.483 0 .879.396.879.879s-.396.88-.88.88Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default ClassroomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const OpenPlanIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="m18.544 41.232-6.706 4.726a.983.983 0 0 0-.246 1.331.907.907 0 0 0 .763.422.881.881 0 0 0 .514-.164l6.706-4.726a.983.983 0 0 0 .247-1.332.896.896 0 0 0-1.277-.257ZM53.555 45.956 46.85 41.23a.898.898 0 0 0-1.277.257.986.986 0 0 0 .247 1.332l6.706 4.726a.885.885 0 0 0 .514.164.911.911 0 0 0 .763-.422.983.983 0 0 0-.247-1.33ZM55.748 28.578l-.03.077a.94.94 0 0 1-.415.458.874.874 0 0 1-.411.109.917.917 0 0 1-.83-.518l-.194-.391-1.449-2.911a.352.352 0 0 0-.481-.16l-6.256 3.384a.373.373 0 0 0-.176.219.387.387 0 0 0 .023.283l2.298 4.617.212.425a.98.98 0 0 1-.35 1.268c-.014.01-.029.017-.043.025a.884.884 0 0 1-.547.1c-.008 0-.014-.002-.022-.004-.012-.001-.024-.005-.038-.006a.922.922 0 0 1-.633-.499l-.822-1.65-1.688-3.392c-.558-1.12-.136-2.505.938-3.088l3.818-2.065.248-.135 2.19-1.184a2.103 2.103 0 0 1 1.675-.15c.56.184 1.017.585 1.287 1.128l.722 1.451.802 1.61.12.241a.991.991 0 0 1 .052.758ZM20.974 26.924l-6.256-3.384c-1.076-.582-2.404-.142-2.962.978l-2.453 4.926a1 1 0 0 0-.103.443c0 .529.413.959.92.959.364 0 .678-.22.827-.539l2.442-4.905a.36.36 0 0 1 .208-.184.347.347 0 0 1 .273.024l6.256 3.384c.113.062.16.163.176.217a.39.39 0 0 1-.023.285l-2.476 4.974a.98.98 0 0 0 .392 1.293c.135.073.281.108.424.108a.916.916 0 0 0 .817-.517l2.476-4.974c.27-.544.322-1.164.146-1.746a2.263 2.263 0 0 0-1.084-1.342Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M60.436 26.645a5.696 5.696 0 0 0-3.534-2.119v-.657c0-3.687-2.879-6.687-6.416-6.687H13.884c-3.537 0-6.415 2.998-6.415 6.686v.658a5.696 5.696 0 0 0-3.534 2.12 6.18 6.18 0 0 0-1.199 4.981l1.259 6.614c.526 2.748 2.856 4.744 5.544 4.744h45.294c2.687 0 5.018-1.996 5.544-4.745l1.258-6.613a6.18 6.18 0 0 0-1.199-4.982ZM9.31 23.868c0-2.63 2.053-4.768 4.575-4.768h36.602c2.524 0 4.577 2.138 4.577 4.77v1.353l-.289.746-.906 2.343-1.74 4.504a2.192 2.192 0 0 1-1.184 1.231 1.986 1.986 0 0 1-1.272.095 30.272 30.272 0 0 0-2.303-.476 45.563 45.563 0 0 0-1.785-.261c-6.959-.887-17.664-.905-25.346-.032-.601.069-1.183.142-1.744.22l-.475.07a36.706 36.706 0 0 0-3.29.627l-.04.01-.03.005c-.329.068-.675.05-1.002-.052-.01-.003-.017-.004-.026-.008a1.985 1.985 0 0 1-.468-.223c-.03-.021-.062-.037-.095-.053l-.003-.002-.009-.008a.852.852 0 0 0-.041-.036l-.028-.023c-.006-.005-.01-.01-.017-.014a2.203 2.203 0 0 1-.673-.927l-1.168-3.023-.146-.377-.012-.032-.748-1.934-.916-2.37v-1.355h.002Zm50.522 7.387-1.26 6.61c-.353 1.856-1.926 3.202-3.738 3.202H9.539c-1.812 0-3.385-1.346-3.74-3.2l-1.258-6.612a4.207 4.207 0 0 1 .817-3.392 3.868 3.868 0 0 1 2.43-1.446l1.466 3.797.012.033.006.014 1.32 3.414c.26.676.687 1.267 1.238 1.713.013.013.029.024.043.035l.009.007a.93.93 0 0 0 .16.099.995.995 0 0 0 .174.137c.26.167.543.302.839.4l.018.006.012.005a3.785 3.785 0 0 0 1.921.112c.056-.011.11-.022.155-.034.988-.242 2.177-.462 3.542-.657l.038-.004.032-.005c6.26-.88 15.256-1.071 22.337-.587.554.039 1.095.08 1.621.127 1.386.12 2.675.27 3.828.447l.48.077c.014.001.026.005.039.006a25.845 25.845 0 0 1 2.151.444c.276.07.562.11.848.117a3.679 3.679 0 0 0 1.559-.295c.995-.418 1.795-1.252 2.198-2.29l1.885-4.88.029-.077.195-.502.64-1.66c.948.153 1.813.661 2.43 1.447a4.207 4.207 0 0 1 .818 3.392Z"
      fill={color || "#111928"}
    />
    <path
      d="M22.83 25.998c.023.058.052.114.085.165a.92.92 0 0 0 .586.407.855.855 0 0 0 .531-.054 1.05 1.05 0 0 0 .16-.088c.049-.035.096-.075.14-.12a.987.987 0 0 0-.14-1.475.915.915 0 0 0-.331-.142.855.855 0 0 0-.532.054.915.915 0 0 0-.499.518 1.048 1.048 0 0 0-.07.368.963.963 0 0 0 .07.367ZM31.336 25.997a.927.927 0 0 0 .85.593.855.855 0 0 0 .35-.074.92.92 0 0 0 .499-.52 1.037 1.037 0 0 0 .07-.365.96.96 0 0 0-.07-.368 1.029 1.029 0 0 0-.084-.165.873.873 0 0 0-.115-.145.851.851 0 0 0-.3-.208.847.847 0 0 0-.53-.054.84.84 0 0 0-.331.142.851.851 0 0 0-.14.12.912.912 0 0 0-.252.49.96.96 0 0 0 .053.554ZM40.181 26.428c.05.034.103.064.158.088a.859.859 0 0 0 .704 0c.055-.024.109-.054.16-.088a.987.987 0 0 0 .14-1.475.906.906 0 0 0-.651-.281.91.91 0 0 0-.765.426.925.925 0 0 0-.138.346.985.985 0 0 0 .392.985Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default OpenPlanIcon;

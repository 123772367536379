import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const MusicRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M31.5 59.786c-2.409 0-5.915-.324-8.939-1.87-3.008-1.537-4.821-3.98-5.243-7.063-.633-4.618 1.343-7 2.786-8.74.622-.748 1.158-1.396 1.398-2.07.79-2.225-.554-6.122-1.711-8.055-.892-1.491-.684-3.311.587-5.126a1.586 1.586 0 0 1 1.58-.658 1.607 1.607 0 0 1 1.285 1.165c.644 2.332 3.976 5.318 5.205 6.272a.88.88 0 1 1-1.078 1.389c-.194-.15-4.617-3.612-5.73-6.892-.717 1.135-.832 2.124-.34 2.946 1.273 2.128 2.905 6.6 1.86 9.547-.345.968-1.004 1.764-1.702 2.605-1.439 1.733-2.926 3.525-2.397 7.378.942 6.879 9.76 7.414 12.44 7.414 3.442 0 11.546-.706 12.44-7.245.531-3.877-1.038-5.823-2.422-7.538-.688-.853-1.338-1.657-1.676-2.613-.686-1.934-.26-4.826.354-7.724-.353.286-.736.654-1.108 1.011-1.271 1.224-2.713 2.61-4.253 1.956a.88.88 0 0 1 .687-1.619c.472.2 1.602-.886 2.349-1.604.576-.554 1.17-1.125 1.765-1.51a1.565 1.565 0 0 1 1.742.03c.525.365.777 1.002.64 1.626-.38 1.751-1.174 5.395-.518 7.244.239.672.77 1.333 1.388 2.096 1.447 1.795 3.43 4.252 2.795 8.88-.422 3.08-2.239 5.495-5.255 6.985-2.978 1.475-6.5 1.783-8.929 1.783ZM34.375 21.512H28.33a.88.88 0 0 1 0-1.758h6.044a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
    <path
      d="M34.375 29.024H28.33a.88.88 0 0 1 0-1.758h6.044a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
    <path
      d="M34.375 38.915a.88.88 0 0 1-.88-.879V14.002H29.21v24.034a.88.88 0 0 1-1.758 0V13.123a.88.88 0 0 1 .88-.879h6.043a.88.88 0 0 1 .879.88v24.912c0 .486-.394.88-.88.88ZM30.498 7.56h-1.826a.88.88 0 0 1 0-1.758H30.5a.88.88 0 1 1-.002 1.758ZM36.776 7.56h-1.827a.88.88 0 0 1 0-1.758h1.827a.88.88 0 0 1 0 1.758ZM36.8 10.565h-1.826a.88.88 0 0 1 0-1.758H36.8a.88.88 0 0 1 0 1.758ZM28.689 10.565H26.86a.88.88 0 0 1 0-1.758h1.828a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
    <path
      d="M34.374 14.002h-6.043a.88.88 0 0 1-.84-.617l-.583-1.866a.88.88 0 0 1 .085-.715l3.692-6.145a.877.877 0 0 1 .509-.39l3.506-1.02a.882.882 0 0 1 1.123.843l.014 7.017a.871.871 0 0 1-.036.246l-.584 2.013a.874.874 0 0 1-.843.634Zm-5.397-1.758h4.737l.364-1.258-.011-5.723-2.051.598-3.312 5.512.273.871Z"
      fill={color || "#111928"}
    />
    <path
      d="M35.331 42.738h-7.958a.88.88 0 0 1-.88-.88v-3.722a.88.88 0 0 1 .88-.88h7.958a.88.88 0 0 1 .88.88v3.723c0 .485-.393.879-.88.879Zm-7.08-1.758h6.201v-1.965h-6.2v1.965Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M39.78 53.335a1.173 1.173 0 0 1-.002-2.344h.002a1.172 1.172 0 1 1 0 2.344ZM38.827 48.378a1.173 1.173 0 0 1-.002-2.344h.002a1.172 1.172 0 1 1 0 2.344ZM35.331 52.553h-7.958a.88.88 0 0 1 0-1.759h7.958a.88.88 0 0 1 0 1.759ZM34.1 47.324h-5.495a.88.88 0 0 1 0-1.759h5.496a.88.88 0 0 1 0 1.759Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default MusicRoomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const LaundryIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M20.255 13.706h-3.019a.77.77 0 0 1-.767-.768.77.77 0 0 1 .767-.767h3.019a.77.77 0 0 1 .767.767.77.77 0 0 1-.767.768ZM43.917 13.706H40.9a.77.77 0 0 1-.768-.768.77.77 0 0 1 .768-.767h3.018a.77.77 0 0 1 .768.767.762.762 0 0 1-.768.768ZM23.107 14.09c-.307 0-.6-.128-.818-.333a.969.969 0 0 1-.141-.18 1.02 1.02 0 0 1-.102-.204 1.08 1.08 0 0 1-.064-.217 1.409 1.409 0 0 1-.026-.23c0-.077.013-.154.026-.23.012-.077.038-.141.064-.218a1.02 1.02 0 0 1 .102-.205.973.973 0 0 1 .32-.32c.064-.038.128-.076.204-.102a1.155 1.155 0 0 1 1.253.256.965.965 0 0 1 .142.179c.038.064.076.128.102.205.025.064.051.14.064.217.013.077.025.154.025.23 0 .307-.127.601-.332.819a1.207 1.207 0 0 1-.819.332ZM25.998 14.09c-.307 0-.601-.128-.819-.333a.969.969 0 0 1-.14-.18c-.039-.063-.077-.127-.103-.204a1.08 1.08 0 0 1-.064-.217c-.012-.077-.025-.154-.025-.23 0-.077.012-.154.025-.23.013-.077.039-.141.064-.218.026-.077.064-.14.102-.205a.973.973 0 0 1 .32-.32c.064-.038.128-.076.205-.102a1.155 1.155 0 0 1 1.253.256.961.961 0 0 1 .141.179c.038.064.077.128.102.205.026.064.052.14.064.217.013.077.026.154.026.23 0 .307-.128.601-.333.819a1.207 1.207 0 0 1-.818.332ZM28.901 14.09c-.307 0-.601-.128-.818-.333a.96.96 0 0 1-.141-.18c-.038-.063-.077-.127-.102-.204a1.086 1.086 0 0 1-.064-.217c-.013-.077-.026-.154-.026-.23 0-.077.013-.154.026-.23.012-.077.038-.141.064-.218.025-.077.064-.14.102-.205a.964.964 0 0 1 .32-.32c.064-.038.128-.076.204-.102a1.155 1.155 0 0 1 1.253.256.97.97 0 0 1 .141.179c.039.064.077.128.103.205.025.064.051.14.064.217.012.077.025.154.025.23 0 .307-.128.601-.332.819a1.232 1.232 0 0 1-.819.332Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M47.537 51.26H13.4a.77.77 0 0 1-.768-.768V9.203a.77.77 0 0 1 .768-.767h34.138a.77.77 0 0 1 .768.767v41.289a.77.77 0 0 1-.768.767Zm-33.37-1.536H46.77V9.971H14.166v39.753Z"
      fill={color || "#111928"}
    />
    <path
      d="M47.537 17.044H13.783a.77.77 0 0 1-.768-.767.77.77 0 0 1 .768-.768h33.741a.77.77 0 0 1 .768.768.759.759 0 0 1-.755.767Z"
      fill={color || "#111928"}
    />
    <path
      d="M22.685 32.853c-.039 0-.09 0-.128-.012a.764.764 0 0 1-.627-.883 8.475 8.475 0 0 1 4.016-5.87.76.76 0 0 1 1.05.268.76.76 0 0 1-.27 1.049 6.962 6.962 0 0 0-3.287 4.809.778.778 0 0 1-.754.64ZM29.22 26.484a.769.769 0 0 1-.754-.653.766.766 0 0 1 .653-.87c.128-.012.268-.038.396-.05a.762.762 0 0 1 .831.69.762.762 0 0 1-.69.832l-.32.038c-.051.013-.077.013-.115.013ZM40.899 38.315c-.052 0-.09 0-.141-.013a5.015 5.015 0 0 1-4.08-4.924c0-2.405 1.714-4.49 4.08-4.925a.776.776 0 0 1 .857.474 12.02 12.02 0 0 1 .844 4.45 12.02 12.02 0 0 1-.844 4.452.77.77 0 0 1-.716.486Zm-.486-8.173a3.494 3.494 0 0 0-2.213 3.236c0 1.445.895 2.724 2.213 3.236.332-1.049.498-2.136.498-3.236s-.166-2.2-.498-3.236Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M30.308 45.53c-1.79 0-3.581-.41-5.244-1.203a12.063 12.063 0 0 1-6.203-6.92c-2.213-6.319 1.113-13.264 7.431-15.477a12.142 12.142 0 0 1 13.2 3.505 12.061 12.061 0 0 1 2.277 3.927c.512 1.445.742 2.954.678 4.49a12.21 12.21 0 0 1-2.303 6.676 12.013 12.013 0 0 1-5.807 4.323 12.507 12.507 0 0 1-4.029.678Zm.039-22.768c-1.177 0-2.38.204-3.543.614-5.513 1.944-8.43 8.007-6.498 13.52a10.55 10.55 0 0 0 5.41 6.05 10.563 10.563 0 0 0 8.11.447c4.144-1.458 6.92-5.218 7.086-9.606a10.744 10.744 0 0 0-.588-3.914 10.685 10.685 0 0 0-1.983-3.428c-2.072-2.392-4.989-3.683-7.994-3.683Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default LaundryIcon;

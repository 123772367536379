import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const TechnologyRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M41.39 8.937a.88.88 0 0 1-.878-.879v-3.01h-4.073v3.01a.88.88 0 0 1-1.758 0V4.17a.88.88 0 0 1 .879-.88h5.83a.88.88 0 0 1 .88.88v3.888c0 .486-.394.88-.88.88ZM41.39 59.747h-5.83a.88.88 0 0 1-.88-.879v-33.15a.88.88 0 0 1 1.759 0V57.99h4.073v-32.27a.88.88 0 0 1 1.758 0v33.15c0 .486-.394.878-.88.878Z"
      fill={color || "#111928"}
    />
    <path
      d="M38.475 26.682c-4.34 0-8.678-.528-11.464-1.586a4.374 4.374 0 0 1-2.8-4.073v-8.636c0-2 1.343-3.736 3.266-4.22 5.55-1.401 16.446-1.401 21.995 0a4.338 4.338 0 0 1 3.266 4.22v8.637c0 1.8-1.125 3.437-2.8 4.073-2.785 1.056-7.123 1.585-11.463 1.585ZM27.907 9.87a2.583 2.583 0 0 0-1.938 2.517v8.637c0 1.075.67 2.051 1.664 2.43 5.168 1.96 16.515 1.96 21.681 0a2.605 2.605 0 0 0 1.665-2.43v-8.637A2.583 2.583 0 0 0 49.04 9.87c-5.232-1.321-15.898-1.321-21.133 0ZM14.821 35.163a.88.88 0 0 1-.88-.88v-5.217a.88.88 0 0 1 1.759 0v5.217a.88.88 0 0 1-.879.88Z"
      fill={color || "#111928"}
    />
    <path
      d="M14.821 29.945a4.565 4.565 0 0 1-4.56-4.559V4.13a.88.88 0 0 1 .88-.879h7.362a.88.88 0 0 1 .88.879v21.255a4.568 4.568 0 0 1-4.562 4.56ZM12.02 5.01v20.375a2.805 2.805 0 0 0 2.801 2.802 2.805 2.805 0 0 0 2.801-2.802V5.01H12.02ZM14.821 59.749a3.817 3.817 0 0 1-3.812-3.811V34.283a.88.88 0 0 1 .879-.879h5.865a.88.88 0 0 1 .879.88v21.652a3.816 3.816 0 0 1-3.811 3.813Zm-2.053-24.587v20.774c0 1.133.922 2.053 2.054 2.053a2.055 2.055 0 0 0 2.053-2.053V35.162h-4.107Z"
      fill={color || "#111928"}
    />
    <path d="M17.753 41.829h-5.864a.88.88 0 0 1 0-1.758h5.865a.88.88 0 1 1-.001 1.758Z" fill="#4045F8" />
  </svg>
);

export default TechnologyRoomIcon;

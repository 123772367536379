import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const MeetingRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M32.222 49.383a7.43 7.43 0 0 1-1.962-.287c-5.26-1.44-17.915-14.283-18.452-14.83a.923.923 0 0 1 1.318-1.293c3.555 3.62 13.653 13.255 17.622 14.342 1.097.3 1.833.253 2.302.121-1.052-.971-2.384-2.483-4.356-4.722-.758-.858-1.615-1.833-2.582-2.917a.923.923 0 1 1 1.378-1.229c.97 1.089 1.83 2.064 2.587 2.924 2.34 2.657 3.752 4.26 4.773 5.058.907.709 1.247.587 1.726.33.618-.333 1.222-.978 1.675-1.595-1.055-.97-2.391-2.487-4.373-4.738a681.575 681.575 0 0 0-2.58-2.917.923.923 0 1 1 1.377-1.229c.97 1.089 1.83 2.064 2.587 2.924 2.228 2.53 3.616 4.106 4.625 4.94.02.01.043.021.063.033a.936.936 0 0 1 .24.2c.793.58 1.12.456 1.571.215.548-.296 1.084-.835 1.514-1.382a5.974 5.974 0 0 1-.292-.217c-1.154-.904-2.61-2.555-5.02-5.29-.757-.859-1.614-1.833-2.58-2.919a.923.923 0 1 1 1.376-1.229c.972 1.088 1.83 2.066 2.59 2.926 2.34 2.656 3.751 4.259 4.771 5.057.194.151.36.266.51.348a.917.917 0 0 1 .592.184c.223.003.41-.086.624-.202.16-.095.728-.574.85-.718.561-.672.4-1.557-.069-2.318a1.112 1.112 0 0 1-.072-.111 4.183 4.183 0 0 0-.592-.7c-2.622-2.488-8.499-7.732-12.122-10.768-1.407 1.218-4.816 3.883-7.705 3.883-.098 0-.195-.003-.292-.009-1.827-.118-2.734-1.024-3.173-1.764-.862-1.449-.623-3.444-.006-4.779.434-.938 1.81-2.73 3.532-4.35h-9.699a.923.923 0 1 1 0-1.846h11.973c1.282-.873 2.611-1.474 3.804-1.49h.096c3.314 0 12.8 2.144 13.206 2.236a.924.924 0 0 1-.41 1.8c-.098-.021-9.861-2.227-12.867-2.19-2.534.034-7.135 4.83-7.96 6.614-.414.893-.575 2.233-.082 3.06.313.528.871.812 1.705.865 2.354.154 6.01-2.78 7.219-3.922a.921.921 0 0 1 1.221-.041c3.562 2.943 10.032 8.705 12.83 11.359.097.093.192.19.285.29l2.699-2.173a.924.924 0 0 1 1.157 1.439l-2.845 2.29c.536 1.252.533 2.665-.42 3.804-.251.302-1.028.966-1.391 1.162-.58.312-1.127.453-1.673.42-.488.69-1.345 1.73-2.412 2.304-1.01.545-1.918.569-2.908.047-.504.681-1.303 1.592-2.277 2.117-.952.513-1.814.564-2.737.132-.48.37-1.28.752-2.49.752Z"
      fill={color || "#111928"}
    />
    <path
      d="M58.623 37.46h-7.32a.922.922 0 0 1-.866-.61l-6.375-17.61a.927.927 0 0 1 .15-.897c.099-.121 2.477-2.984 7.95-4.232 3.153-.72 6.403-.455 6.54-.443.477.041.845.44.845.92v21.95c0 .509-.414.922-.923.922Zm-6.67-1.846h5.749V15.47c-1.116-.03-3.17-.003-5.128.444-3.74.852-5.815 2.51-6.581 3.238l5.96 16.463ZM11.694 37.46H4.376a.923.923 0 0 1-.923-.923V14.59c0-.495.391-.903.885-.922.133-.004 3.314-.127 6.457.437 4.618.83 6.502 1.98 6.58 2.027a.922.922 0 0 1 .404 1.02l-5.192 19.623a.922.922 0 0 1-.893.685ZM5.3 35.614h5.686l4.818-18.207c-.818-.357-2.516-.98-5.335-1.487-1.988-.357-4.038-.419-5.169-.422v20.116Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default MeetingRoomIcon;

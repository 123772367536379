import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";
const BedroomIcon = ({ height = 63, width = 66, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 66">
    <path
      d="M48.26 23.147h-4.014c-.483 0-.879-.412-.879-.917 0-.505.396-.917.88-.917h3.135v-7.46c0-2.355-1.846-4.282-4.103-4.282H19.72c-2.256 0-4.102 1.927-4.102 4.281v7.46h3.253c.483 0 .879.413.879.918 0 .505-.396.917-.88.917H14.74c-.484 0-.88-.412-.88-.917v-8.378c0-3.379 2.623-6.115 5.861-6.115h23.545c3.238 0 5.86 2.736 5.86 6.115v8.378a.88.88 0 0 1-.864.917Z"
      fill={color || "#111928"}
    />
    <path
      d="M33.58 23.147h-4.029c-.483 0-.879-.412-.879-.917 0-.505.396-.917.88-.917h4.029c.483 0 .879.413.879.917 0 .505-.396.917-.88.917Z"
      fill={color || "#111928"}
    />
    <path
      d="M54.956 46.294H8.044a.863.863 0 0 1-.703-.367.935.935 0 0 1-.132-.81l6.71-23.147c.118-.382.455-.657.836-.657h4.131c.484 0 .88.413.88.917 0 .505-.396.917-.88.917h-3.487L9.23 44.46h44.54l-6.168-21.312h-3.37c-.484 0-.88-.412-.88-.917 0-.505.396-.917.88-.917h4.014c.381 0 .733.26.835.657l6.71 23.146a.986.986 0 0 1-.131.81.863.863 0 0 1-.703.368Z"
      fill={color || "#111928"}
    />
    <path
      d="M33.58 23.147h-4.029c-.483 0-.879-.412-.879-.917 0-.505.396-.917.88-.917h4.029c.483 0 .879.413.879.917 0 .505-.396.917-.88.917ZM8.044 58.524c-.484 0-.88-.413-.88-.917V45.361c0-.504.396-.917.88-.917.483 0 .879.413.879.917v12.246c0 .505-.396.917-.88.917ZM54.957 58.524c-.484 0-.88-.413-.88-.917V45.361c0-.504.396-.917.88-.917.483 0 .879.413.879.917v12.246c0 .505-.396.917-.88.917Z"
      fill={color || "#111928"}
    />
    <path
      d="M54.957 54.442H8.044c-.484 0-.88-.413-.88-.917 0-.505.396-.917.88-.917h46.913c.483 0 .879.412.879.917 0 .505-.396.917-.88.917Z"
      fill={color || "#111928"}
    />
    <path
      d="M29.552 26.022H18.885c-.483 0-.879-.413-.879-.918v-8.821c0-.505.396-.917.88-.917h10.666c.483 0 .878.412.878.917v8.821c0 .505-.395.918-.878.918Zm-9.787-1.835h8.907V17.2h-8.907v6.987ZM44.246 26.022H33.58c-.483 0-.879-.413-.879-.918v-8.821c0-.505.396-.917.88-.917h10.665c.484 0 .88.412.88.917v8.821c0 .505-.396.918-.88.918Zm-9.787-1.835h8.908V17.2H34.46v6.987ZM54.956 46.294H8.044a.863.863 0 0 1-.703-.367.935.935 0 0 1-.132-.81l4.733-16.313c.117-.382.454-.657.835-.657h37.463c.38 0 .732.26.835.657l4.732 16.312c.074.276.03.581-.132.81a.89.89 0 0 1-.718.367ZM9.23 44.459h44.54l-4.19-14.478H13.42L9.23 44.46Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default BedroomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const WeightsIcon = ({ height = 63, width = 63 }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 45 45" fill="none">
    <path
      d="M10.042 26.895h-.036l-2.03-.027a2.624 2.624 0 0 1-2.583-2.655l.01-.792a2.623 2.623 0 0 1 2.618-2.584h.037l3.425.047c.327.004.631.136.86.37.228.235.351.544.347.87l-.03 2.188a2.62 2.62 0 0 1-2.618 2.583Zm-2.018-4.802c-.743 0-1.354.6-1.363 1.345l-.01.792a1.366 1.366 0 0 0 1.344 1.382l2.029.027h.018c.743 0 1.353-.6 1.364-1.344l.03-2.156-3.394-.046h-.018Zm4.01 2.21Z"
      fill="#111928"
    />
    <path
      d="m40.394 34.906-.584-9.677c-.087-1.497-1.337-2.68-2.787-2.648l-10.02-.418a.628.628 0 0 0-.054 1.255l10.043.42h.046c.787-.025 1.47.63 1.518 1.464l.605 10.046c.015.236.16.444.377.538.015.006 1.247.572 1.44 1.271l-3.951-.002-1.254-9.688a.628.628 0 0 0-.672-.545l-8.151.634H3.362a.628.628 0 0 0-.627.628v3.771c0 .346.281.628.627.628H5.65v4.612H4.456a.628.628 0 0 0 0 1.256H8.1a.628.628 0 0 0 0-1.256H6.906v-4.612h22.068v4.612H27.78a.628.628 0 0 0 0 1.256h3.644a.628.628 0 0 0 0-1.256H30.23v-4.612h2.279a.628.628 0 0 0 .628-.628v-.16a4.233 4.233 0 0 0-1.627-3.333l3.096-.241 1.247 9.643c.041.313.307.547.623.547l5.076.002a.628.628 0 0 0 .587-.406c.54-1.432-.713-2.55-1.745-3.1ZM3.99 31.328v-2.515h24.906a2.989 2.989 0 0 1 2.947 2.515H3.99ZM19.714 12.513a.614.614 0 0 1-.24-.048.623.623 0 0 1-.204-.136.63.63 0 0 1 0-.889.687.687 0 0 1 .204-.135.648.648 0 0 1 .48 0 .696.696 0 0 1 .204.135.69.69 0 0 1 .136.204.663.663 0 0 1 .037.117.634.634 0 0 1-.376.703.662.662 0 0 1-.24.049Z"
      fill="#111928"
    />
    <path
      d="m27.05 22.167-6.924-.813.614-1.59a.787.787 0 0 0 .026-.083l.717-3.088a.628.628 0 1 0-1.224-.283l-.706 3.044-1.798 4.664a.629.629 0 0 0 1.172.453l.735-1.906 6.688.785v4.21l-8.77.02a3.577 3.577 0 0 1-.19-.017c-.718-.085-1.127-.344-1.146-.357a2.639 2.639 0 0 1-1.277-2.284c.004-.392.092-.768.262-1.116l.01-.02.01-.02a.07.07 0 0 0 .004-.01l2.856-5.68a.642.642 0 0 0 .048-.13l.386-1.548a.627.627 0 1 0-1.218-.304l-.372 1.479-2.833 5.64-.002.002-.007.014-.005.01-.007.014-.012.024a3.84 3.84 0 0 0-.379 1.631 3.897 3.897 0 0 0 1.86 3.357c.164.106.742.434 1.671.543a4.415 4.415 0 0 0 .318.026h.001l9.418-.021a.628.628 0 0 0 .627-.628V22.79a.627.627 0 0 0-.554-.623Z"
      fill="#111928"
    />
    <path
      d="M19.714 17.222a5.343 5.343 0 0 1-5.337-5.337 5.343 5.343 0 0 1 5.337-5.337 5.343 5.343 0 0 1 5.337 5.337 5.343 5.343 0 0 1-5.337 5.337Zm0-9.418a4.086 4.086 0 0 0-4.081 4.081 4.087 4.087 0 0 0 4.081 4.082 4.087 4.087 0 0 0 4.082-4.082 4.085 4.085 0 0 0-4.082-4.081Z"
      fill="#111928"
    />
  </svg>
);

export default WeightsIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const StairwellIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M57.468 21.988h-8.126a.88.88 0 0 0-.879.88v4.975h-7.246a.88.88 0 0 0-.88.88v5.757h-7.246a.88.88 0 0 0-.879.88v5.757h-.886l-1.649-2.343v-5.109a4.738 4.738 0 0 0-1.843-3.765l-4.26-3.308a.88.88 0 0 0-.364-.167.361.361 0 0 0-.059-.009.88.88 0 0 0-.897.447l-5.34 9.737a2.991 2.991 0 0 1-1.73 1.423L6.42 40.796a3.374 3.374 0 0 1 2.347-3.35l5.918-1.873a.876.876 0 0 0 .568-.561l3.001-9.033a.88.88 0 0 0-1.669-.555l-2.86 8.606-5.49 1.738a5.125 5.125 0 0 0-3.341 6.427.88.88 0 0 0 1.104.573l9.712-3.074a4.75 4.75 0 0 0 2.743-2.253l2.634-4.804 3.032 1.73v7.387a.869.869 0 0 0-.034.24v5.758H16.84a.88.88 0 0 0-.88.88v6.323a.88.88 0 0 0 1.759 0v-5.444h7.246a.88.88 0 0 0 .88-.88V42.91h5.048a.84.84 0 0 0 .243-.035h1.955a.88.88 0 0 0 .879-.88v-5.757h7.246a.88.88 0 0 0 .88-.88V29.6h7.246a.88.88 0 0 0 .879-.88v-4.975h7.246a.877.877 0 1 0 .001-1.757ZM21.93 31.096l1.366-2.491 3.455 2.683a2.989 2.989 0 0 1 1.163 2.377v5.388c0 .181.056.357.16.505l1.096 1.557h-3.297v-7.258a.88.88 0 0 0-.444-.763l-3.499-1.998Z"
      fill={color || "#111928"}
    />
    <path
      d="m38.382 17.058-.178-.56a.88.88 0 0 0-1.104-.575l-6.901 2.183-7.9-2.834a.887.887 0 0 0-.607-.076c-.001 0-.003 0-.003.002-.02.004-.04.008-.061.016l-.015.004-7.895 2.498a.882.882 0 0 0-.544.494l-3.797 8.915a.88.88 0 0 0 .467 1.154l.378.16c.44.186.898.273 1.35.273 1.352 0 2.647-.783 3.226-2.082l1.799-4.05 1.513-.479-1.494 3.226-.008.02-.018.045-.015.044-.008.022-.004.017c-.004.016-.007.034-.012.05l-.007.037a.528.528 0 0 0-.009.088l-.001.044v.042l.003.04.006.045.005.038.01.046.01.036.014.044a.397.397 0 0 0 .015.038l.016.04.019.04a.418.418 0 0 0 .019.035l.025.04.02.03.03.038.023.03.03.033.029.028a.578.578 0 0 0 .1.08l.04.027.031.017c.015.01.03.017.047.025l.015.008.019.007c.017.007.034.015.051.02l.041.014.02.006 5.603 1.583a.62.62 0 0 0 .052.01c.014.003.029.008.043.01a.923.923 0 0 0 .142.013H23.021a.947.947 0 0 0 .13-.01l.05-.012a.733.733 0 0 0 .081-.021l.053-.02c.025-.01.05-.02.073-.031l.05-.028c.022-.013.045-.026.066-.041l.045-.035c.02-.016.04-.034.059-.052a.402.402 0 0 0 .04-.044c.018-.018.033-.038.05-.058a.705.705 0 0 0 .074-.117l.03-.059c.004-.01.01-.018.012-.028l2.236-5.664 3.489 1.383a.875.875 0 0 0 .589.02l5.942-1.88a3.507 3.507 0 0 0 2.292-4.402Zm-22.69 3.968a.875.875 0 0 0-.538.48l-1.96 4.412a1.765 1.765 0 0 1-1.825 1.027l3.268-7.673 5.608-1.774-1.133 2.445-3.42 1.083Zm19.87-1.237-5.644 1.786-4.016-1.592-1.055-.42a.88.88 0 0 0-.648 1.636l.242.095-1.946 4.927-3.847-1.087 3.695-7.978 7.544 2.707a.88.88 0 0 0 .563.01l6.32-1.999c.117.831-.38 1.655-1.207 1.915Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M26.294 15.798a.892.892 0 0 1-.262-.04l-2.064-.647a2.719 2.719 0 0 1-1.779-3.403l.828-2.639a2.696 2.696 0 0 1 1.333-1.594 2.7 2.7 0 0 1 2.07-.185l.314.099a2.719 2.719 0 0 1 1.779 3.403l-1.376 4.391a.878.878 0 0 1-.843.615Zm-.69-6.874a.947.947 0 0 0-.442.11.95.95 0 0 0-.47.561l-.828 2.639a.958.958 0 0 0 .627 1.2l1.226.384 1.114-3.552a.958.958 0 0 0-.627-1.2l-.314-.098a.96.96 0 0 0-.286-.044Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default StairwellIcon;

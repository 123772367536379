import * as React from "react";
import { number } from "yup";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const LabRoomIcon = ({ width = 43, height = 53, color }: RoomIconSVGProps) => (
  <svg width={width} height={height} viewBox="0 0 43 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1007 35.4124H7.28239C6.79744 35.4124 6.40332 35.0183 6.40332 34.5334C6.40332 34.0484 6.79744 33.6543 7.28239 33.6543H20.1007C20.5857 33.6543 20.9798 34.0484 20.9798 34.5334C20.9798 35.0183 20.5871 35.4124 20.1007 35.4124Z"
      fill={color || "#111928"}
    />
    <path
      d="M22.7073 52.2521H4.6805C3.11283 52.2521 1.68727 51.4565 0.86388 50.1233C0.0404861 48.79 -0.0313044 47.1579 0.670485 45.7572L9.18426 28.7897V18.2716C9.18426 17.7867 9.57838 17.3926 10.0633 17.3926C10.5483 17.3926 10.9424 17.7867 10.9424 18.2716V28.9992C10.9424 29.1355 10.9102 29.2717 10.8486 29.3933L2.24255 46.544C1.8162 47.3952 1.86016 48.3886 2.35976 49.1988C2.85937 50.009 3.72818 50.4925 4.6805 50.4925H22.7073C23.6611 50.4925 24.5299 50.009 25.0295 49.1973C25.5291 48.3871 25.5716 47.3952 25.1452 46.544L16.5377 29.3904C16.4761 29.2688 16.4439 29.1326 16.4439 28.9963V18.2687C16.4439 17.7838 16.838 17.3896 17.323 17.3896C17.8079 17.3896 18.202 17.7838 18.202 18.2687V28.7868L26.7158 45.7543C27.4191 47.1535 27.3487 48.7856 26.5254 50.1189C25.7034 51.4551 24.2764 52.2521 22.7073 52.2521Z"
      fill={color || "#111928"}
    />
    <path
      d="M8.74179 48.7873C7.03639 48.7873 5.64893 47.3998 5.64893 45.6944C5.64893 43.989 7.03639 42.6016 8.74179 42.6016C10.4472 42.6016 11.8346 43.989 11.8346 45.6944C11.8346 47.3998 10.4472 48.7873 8.74179 48.7873ZM8.74179 44.3597C8.0063 44.3597 7.40707 44.9589 7.40707 45.6944C7.40707 46.4299 8.0063 47.0291 8.74179 47.0291C9.47727 47.0291 10.0765 46.4299 10.0765 45.6944C10.0765 44.9589 9.47874 44.3597 8.74179 44.3597Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M16.7896 44.0094C15.2131 44.0094 13.9297 42.726 13.9297 41.1495C13.9297 39.573 15.2131 38.2896 16.7896 38.2896C18.3661 38.2896 19.6495 39.573 19.6495 41.1495C19.6495 42.7274 18.3661 44.0094 16.7896 44.0094ZM16.7896 40.0477C16.1816 40.0477 15.6878 40.5415 15.6878 41.1495C15.6878 41.7575 16.1816 42.2513 16.7896 42.2513C17.3976 42.2513 17.8914 41.7575 17.8914 41.1495C17.8914 40.5415 17.3976 40.0477 16.7896 40.0477Z"
      fill={color || "#111928"}
    />
    <path
      d="M40.1261 42.8051H32.3361C31.8511 42.8051 31.457 42.411 31.457 41.926C31.457 41.441 31.8511 41.0469 32.3361 41.0469H40.1261C40.6111 41.0469 41.0052 41.441 41.0052 41.926C41.0052 42.411 40.6125 42.8051 40.1261 42.8051Z"
      fill={color || "#111928"}
    />
    <path
      d="M36.2317 52.0718C33.5989 52.0718 31.4583 49.9298 31.4583 47.2985V19.5009C31.4583 19.2621 31.2649 19.0687 31.0261 19.0687H30.5339C30.0489 19.0687 29.6548 18.6746 29.6548 18.1896C29.6548 17.7047 30.0489 17.3105 30.5339 17.3105H31.0261C32.2334 17.3105 33.2165 18.2936 33.2165 19.5009V47.2985C33.2165 48.9614 34.5688 50.3137 36.2317 50.3137C37.8946 50.3137 39.2469 48.9614 39.2469 47.297V19.4994C39.2469 18.2922 40.23 17.3091 41.4372 17.3091H41.9281C42.413 17.3091 42.8071 17.7032 42.8071 18.1881C42.8071 18.6731 42.413 19.0672 41.9281 19.0672H41.4372C41.1984 19.0672 41.005 19.2606 41.005 19.4994V47.297C41.0065 49.9298 38.8645 52.0718 36.2317 52.0718Z"
      fill={color || "#111928"}
    />
    <path
      d="M8.75934 13.9835C7.11695 13.9835 5.78076 12.6473 5.78076 11.0049C5.78076 9.36255 7.11695 8.02637 8.75934 8.02637C10.4017 8.02637 11.7379 9.36255 11.7379 11.0049C11.7379 12.6473 10.4017 13.9835 8.75934 13.9835ZM8.75934 9.78304C8.08685 9.78304 7.5389 10.331 7.5389 11.0035C7.5389 11.676 8.08685 12.2239 8.75934 12.2239C9.4333 12.2239 9.97978 11.676 9.97978 11.0035C9.97978 10.331 9.4333 9.78304 8.75934 9.78304Z"
      fill={color || "#111928"}
    />
    <path
      d="M21.6362 10.4584C18.9594 10.4584 16.7808 8.27973 16.7808 5.60296C16.7808 2.92619 18.9594 0.747559 21.6362 0.747559C24.3129 0.747559 26.4916 2.92619 26.4916 5.60296C26.4901 8.27973 24.3129 10.4584 21.6362 10.4584ZM21.6362 2.5057C19.9278 2.5057 18.5389 3.89463 18.5389 5.60296C18.5389 7.31129 19.9278 8.70022 21.6362 8.70022C23.3445 8.70022 24.7334 7.31129 24.7334 5.60296C24.7334 3.89463 23.343 2.5057 21.6362 2.5057Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M36.2653 14.2459C34.4368 14.2459 32.9497 12.7588 32.9497 10.9303C32.9497 9.10184 34.4368 7.61475 36.2653 7.61475C38.0937 7.61475 39.5808 9.10184 39.5808 10.9303C39.5808 12.7588 38.0937 14.2459 36.2653 14.2459ZM36.2653 9.37142C35.4067 9.37142 34.7078 10.0703 34.7078 10.9288C34.7078 11.7874 35.4067 12.4863 36.2653 12.4863C37.1238 12.4863 37.8227 11.7874 37.8227 10.9288C37.8227 10.0703 37.1238 9.37142 36.2653 9.37142Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default LabRoomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const OtherRoomIcon = ({ width = 35, height = 33, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 35 33">
    <path
      d="M.977 20.287h12.656v12.276h7.705V20.287h12.685v-7.705H21.338V.307h-7.705v12.275H.977v7.705Z"
      fill={color || "#000"}
    />
  </svg>
);

export default OtherRoomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const LibraryIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M18.588 18.143a.88.88 0 0 1-.879-.88v-4.14a.88.88 0 0 1 1.758 0v4.142a.879.879 0 0 1-.879.878ZM24.54 18.143a.88.88 0 0 1-.878-.88v-4.14a.88.88 0 0 1 1.758 0v4.142a.879.879 0 0 1-.88.878ZM51.313 25.086a.88.88 0 0 1-.88-.88V13.124a.88.88 0 0 1 1.759 0v11.084a.88.88 0 0 1-.88.879ZM45.62 19.543H30.324a.88.88 0 0 1 0-1.758h15.298a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
    <path
      d="M56.696 29.406H15.187c-2.685 0-5.145-1.05-6.927-2.956-1.855-1.985-2.835-4.699-2.835-7.846 0-5.957 4.38-10.804 9.762-10.804h41.509a.88.88 0 0 1 .879.88A5.327 5.327 0 0 1 52.253 14H16.537a4.635 4.635 0 0 0-3.298 1.368 4.636 4.636 0 0 0-1.366 3.296 4.668 4.668 0 0 0 4.664 4.662h2.05a.88.88 0 0 1 0 1.758h-2.05a6.428 6.428 0 0 1-6.422-6.42c0-1.714.668-3.326 1.88-4.54a6.386 6.386 0 0 1 4.542-1.882h35.716a3.57 3.57 0 0 0 3.454-2.684h-40.52c-4.413 0-8.004 4.059-8.004 9.046 0 5.326 3.291 9.044 8.004 9.044h40.515a3.45 3.45 0 0 0-3.328-2.564H24.54a.88.88 0 0 1 0-1.758h27.832a5.207 5.207 0 0 1 5.202 5.201c0 .487-.395.88-.88.88Z"
      fill={color || "#111928"}
    />
    <path
      d="M18.588 28.497a.88.88 0 0 1-.88-.88V16.776a.88.88 0 0 1 1.76 0v8.53l1.44-1.618a.879.879 0 0 1 1.312 0l1.44 1.619v-8.531a.88.88 0 0 1 1.758 0v10.841a.88.88 0 0 1-1.537.585l-2.319-2.608-2.319 2.608a.875.875 0 0 1-.655.294ZM11.687 50.876a.88.88 0 0 1-.879-.879V38.914a.88.88 0 0 1 1.758 0v11.083c0 .487-.394.88-.879.88ZM32.676 45.335H17.38a.88.88 0 0 1 0-1.758h15.297a.88.88 0 0 1 0 1.758ZM45.723 45.335h-6.748a.88.88 0 0 1 0-1.758h6.748a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
    <path
      d="M47.813 55.198H6.304a.88.88 0 0 1-.879-.879 5.206 5.206 0 0 1 5.202-5.2h35.838a4.667 4.667 0 0 0 4.662-4.663 4.668 4.668 0 0 0-4.662-4.662H10.748a5.327 5.327 0 0 1-5.321-5.321.88.88 0 0 1 .879-.88h41.508c5.383 0 9.762 4.847 9.762 10.804 0 3.148-.98 5.86-2.835 7.846-1.783 1.906-4.243 2.955-6.928 2.955ZM7.296 53.44h40.515c4.713 0 8.004-3.718 8.004-9.044 0-4.987-3.591-9.046-8.004-9.046H7.293a3.57 3.57 0 0 0 3.454 2.684h35.716c3.541 0 6.42 2.88 6.42 6.42s-2.88 6.42-6.42 6.42H10.625a3.452 3.452 0 0 0-3.329 2.566Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default LibraryIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const LockerRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M26.461 16.211a.858.858 0 0 1-.858-.858l-.002-4.054c0-1.333.586-2.58 1.65-3.512 1.031-.903 2.395-1.4 3.84-1.4h.812c3.028 0 5.49 2.188 5.49 4.878l.002 4.02a.858.858 0 1 1-1.717 0l-.001-4.021c0-1.744-1.694-3.164-3.776-3.164h-.812c-1.028 0-1.99.346-2.709.976-.685.6-1.063 1.39-1.063 2.22l.001 4.054a.856.856 0 0 1-.857.861c.002 0 0 0 0 0ZM53.779 52.66a.858.858 0 0 1-.86-.858V41.31c0-2.077-1.14-4.131-2.491-4.483l-3.134-.818a.859.859 0 0 1 .434-1.661l3.133.818c2.117.552 3.776 3.25 3.776 6.144v10.492a.86.86 0 0 1-.858.858Zm-44.216 0a.858.858 0 0 1-.859-.858V41.31c0-2.886 1.562-5.583 3.557-6.14l2.93-.818a.858.858 0 1 1 .46 1.655l-2.928.817c-1.089.303-2.302 2.221-2.302 4.488v10.491a.857.857 0 0 1-.858.857Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M54.118 56.612H8.881c-1.45 0-2.63-1.18-2.63-2.63v-.136c0-1.45 1.18-2.63 2.63-2.63h45.237c1.45 0 2.63 1.18 2.63 2.63v.136c0 1.45-1.18 2.63-2.63 2.63Zm-45.237-3.68a.914.914 0 0 0-.912.914v.136c0 .504.41.913.912.913h45.237c.504 0 .913-.41.913-.913v-.136a.914.914 0 0 0-.913-.913H8.881Z"
      fill={color || "#111928"}
    />
    <path
      d="M15.694 53a.858.858 0 0 1-.859-.858V30.546c0-9.19 7.476-16.664 16.665-16.664s16.664 7.475 16.664 16.664v21.392a.858.858 0 1 1-1.717 0V30.546c0-8.241-6.706-14.947-14.947-14.947-8.242 0-14.947 6.706-14.947 14.947v21.596a.858.858 0 0 1-.859.858Z"
      fill={color || "#111928"}
    />
    <path
      d="M38.449 52.933h-14.17a5.497 5.497 0 0 1-5.492-5.491v-6.268a5.497 5.497 0 0 1 5.491-5.491H38.45a5.497 5.497 0 0 1 5.491 5.49v6.269a5.497 5.497 0 0 1-5.491 5.49ZM24.279 37.4a3.78 3.78 0 0 0-3.775 3.774v6.268a3.78 3.78 0 0 0 3.774 3.774H38.45a3.78 3.78 0 0 0 3.774-3.774v-6.268A3.78 3.78 0 0 0 38.45 37.4h-14.17Z"
      fill={color || "#111928"}
    />
    <path
      d="M38.721 42.372h-14.75a.858.858 0 1 1 0-1.717h14.75a.858.858 0 1 1 0 1.717ZM38.721 28.27H24.416c-3.027 0-5.493-2.01-5.495-4.481l-.003-3.625c0-.475.384-.858.858-.858h.001c.473 0 .859.383.859.857l.002 3.625c.002 1.525 1.697 2.767 3.78 2.767h14.306c1.198 0 2.34-.426 3.055-1.143.326-.326.716-.868.716-1.59l-.006-3.59c0-.475.384-.859.857-.859h.002c.473 0 .858.384.858.857l.003 3.591c.002 1.021-.432 2.018-1.217 2.804-1.045 1.047-2.6 1.646-4.27 1.646Z"
      fill={color || "#111928"}
    />
    <path
      d="M37.29 32.903a.858.858 0 0 1-.859-.858v-6.268a.858.858 0 1 1 1.718 0v6.268a.858.858 0 0 1-.86.858Zm-11.718 0a.858.858 0 0 1-.859-.858v-6.268a.858.858 0 1 1 1.717 0v6.268a.858.858 0 0 1-.858.858ZM38.721 44.689a.858.858 0 0 1-.858-.859v-2.249a.858.858 0 1 1 1.717 0v2.25a.858.858 0 0 1-.859.858Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default LockerRoomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const Garage = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M47.03 41.61H47a.88.88 0 0 1 0-1.757h.03a.88.88 0 0 1 0 1.758ZM59.17 35.784h-4.385a3.095 3.095 0 0 1-3.093-3.091v-.556a3.096 3.096 0 0 1 3.093-3.093h.794a.88.88 0 0 1 0 1.759h-.794c-.735 0-1.334.599-1.334 1.334v.556c0 .735.599 1.333 1.334 1.333h4.385a.88.88 0 0 1 0 1.758ZM4.616 36.032a.88.88 0 0 1-.581-1.54l3.233-2.847a.142.142 0 0 0 .049-.142.143.143 0 0 0-.098-.114L4.32 30.362a.88.88 0 0 1 .587-1.657l2.898 1.025a1.891 1.891 0 0 1 1.237 1.437 1.89 1.89 0 0 1-.613 1.795L5.195 35.81a.86.86 0 0 1-.579.223Z"
      fill={color || "#4045F8"}
    />
    <path
      d="m60.232 37.314-.175-2.431a.844.844 0 0 0-.018-.122 8.699 8.699 0 0 0-3.928-5.544 8.576 8.576 0 0 0-3.207-1.18l-10.25-1.65c-2.756-4.248-8.203-9.957-12.23-9.957H13.578c-2.39.096-4.778 2.334-7.097 6.651a44.74 44.74 0 0 0-2.661 6.07c-1.69 2.673-1.014 4.972-.207 6.152-.359 1.006-.99 3.318-.277 5.685a.878.878 0 0 0 .843.626h3.663a5.85 5.85 0 0 0 5.774 4.96 5.85 5.85 0 0 0 5.774-4.96h21.855a5.85 5.85 0 0 0 5.774 4.96 5.85 5.85 0 0 0 5.774-4.96h3.213a4.238 4.238 0 0 0 4.234-4.234c-.006-.024-.006-.044-.009-.066ZM40.475 26.312H25.977l-.978-8.126h5.424c2.667 0 7.14 4.049 10.052 8.126ZM23.23 18.186l.978 8.126H13.769l3.305-8.126h6.155Zm-9.598 0h1.545l-3.306 8.126H6.852c.345-.765.743-1.593 1.187-2.417 1.938-3.603 3.924-5.63 5.592-5.709ZM5.415 35.52a.867.867 0 0 0-.161-.974c-.172-.185-1.63-1.878.1-4.532a.838.838 0 0 0 .096-.199c.005-.015.239-.706.655-1.745h18.213v11.784h-4.933a5.85 5.85 0 0 0-5.774-4.96 5.85 5.85 0 0 0-5.774 4.96H4.869c-.4-2.22.536-4.312.546-4.334Zm8.197 9.293a4.087 4.087 0 0 1-4.081-4.073v-.014a4.086 4.086 0 0 1 4.081-4.075 4.087 4.087 0 0 1 4.082 4.076v.012a4.085 4.085 0 0 1-4.082 4.075Zm33.402 0a4.087 4.087 0 0 1-4.082-4.073v-.014a4.086 4.086 0 0 1 4.082-4.075 4.086 4.086 0 0 1 4.082 4.076v.012a4.085 4.085 0 0 1-4.082 4.075Zm8.987-4.959h-3.213a5.85 5.85 0 0 0-5.774-4.96 5.85 5.85 0 0 0-5.774 4.96H26.076V28.07h15.977l10.575 1.704a6.878 6.878 0 0 1 2.56.94 6.936 6.936 0 0 1 3.118 4.36l.168 2.333a2.476 2.476 0 0 1-2.473 2.447Z"
      fill={color || "#111928"}
    />
    <path
      d="M32.23 32.361h-3.128a.88.88 0 0 1 0-1.758h3.128a.88.88 0 0 1 0 1.758ZM13.629 41.61h-.03a.88.88 0 0 1 0-1.757h.03a.88.88 0 0 1 0 1.758Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default Garage;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const StaffRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M52.224 42.523c-.004-1.24-.098-2.246-.961-2.246h-.044c-.188.006-.352.066-.561.226a1.665 1.665 0 0 0-1.583 0c-.209-.16-.373-.22-.56-.226h-.046c-.862 0-.955 1.007-.961 2.246v.214c0 1.199.974 2.173 2.173 2.173h.369a2.175 2.175 0 0 0 2.173-2.173v-.196l.001-.018ZM44.36 33.393h-.162c-.71 0-1.412-.1-2.088-.296a.88.88 0 0 1 .491-1.689c.516.15 1.052.226 1.597.226h.161c1.037 0 2.054-.28 2.938-.812a5.753 5.753 0 0 0 2.782-4.908v-3.619a.88.88 0 0 1 1.758 0v3.62a7.521 7.521 0 0 1-3.635 6.416 7.478 7.478 0 0 1-3.843 1.062Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M55.818 48.08h-2.32a.88.88 0 0 1 0-1.757h2.32a.772.772 0 0 0 .772-.772v-3.398c0-4.193-3.412-7.605-7.605-7.605h-.46c-.43 1.914-2.154 3.356-4.186 3.356h-.268c-.312 0-.624-.034-.93-.1a.88.88 0 0 1 .379-1.718c.18.04.366.06.55.06h.269a2.54 2.54 0 0 0 2.53-2.485l.002-2.083a.88.88 0 0 1 1.758 0v1.211h.356c5.163 0 9.363 4.2 9.363 9.364v3.398a2.534 2.534 0 0 1-2.53 2.53Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M53.497 48.08h-.02a.88.88 0 1 1 .18-1.743.882.882 0 0 1 .71.948.873.873 0 0 1-.87.796Zm-.007-.839v.837a.849.849 0 0 0 .256-.041l-.256-.796Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M47.011 49.893h-1.33a.88.88 0 0 1 0-1.758h1.314c.016-.002.034-.002.05-.002.668 0 1.081-.21 1.705-.526.904-.458 2.142-1.085 4.658-1.282a.88.88 0 0 1 .137 1.753c-2.168.17-3.142.663-4 1.099-.719.364-1.398.707-2.467.715l-.067.001Zm-.088-1.752s-.001 0 0 0c-.001 0 0 0 0 0Zm.022-.003-.022.003c.008 0 .015-.002.022-.003ZM50.958 23.512a.881.881 0 0 1-.878-.932s.005-.093 0-.253v-.007a6.378 6.378 0 0 0-2.81-4.97c-.67 1.262-2.257 3.408-5.757 4.971a.88.88 0 0 1-.718-1.606c4.334-1.936 5.185-4.75 5.22-4.87a.868.868 0 0 1 .495-.572.878.878 0 0 1 .759.029c1.855.967 2.958 2.266 3.614 3.499a79.816 79.816 0 0 0-.029-1.117l-.026-.91a2.668 2.668 0 0 0-.355-1.28c-.473-.83-1.285-1.29-1.516-1.41-.508-.112-3.244-.66-5.094.084a5.165 5.165 0 0 1-2.106.586 2.224 2.224 0 0 1-1.553-.53c-.115-.099-.237-.213-.367-.333-.29-.268-.651-.604-.975-.809-.12-.076-.423-.09-.536-.023a1.93 1.93 0 0 0-.488.394.879.879 0 1 1-1.31-1.172c.265-.296.576-.548.923-.747.677-.389 1.691-.36 2.358.067.455.29.882.686 1.224 1.002.116.107.22.205.311.281.092.078.212.12.325.115.219-.01.789-.072 1.405-.403.027-.013.053-.027.08-.038 2.574-1.065 6.156-.2 6.307-.161a.795.795 0 0 1 .14.048c.063.028 1.55.69 2.399 2.18.37.652.567 1.361.583 2.11.007.303.016.605.025.898.086 2.845.15 4.902-1.185 5.745a.857.857 0 0 1-.465.134ZM18.68 33.246h-.16a7.457 7.457 0 0 1-4.01-1.165 7.453 7.453 0 0 1-3.467-6.313v-3.235a.88.88 0 0 1 1.758 0v3.237c0 1.966.992 3.771 2.653 4.83.914.582 1.975.89 3.066.89h.16c.755 0 1.484-.144 2.17-.427a.88.88 0 0 1 .672 1.625c-.9.37-1.856.558-2.842.558Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M18.662 37.757h-.27c-2.322 0-4.242-1.882-4.287-4.202-.002-.029-.002-.058-.002-.088a.88.88 0 0 1 1.759 0v.003l.001.038A2.54 2.54 0 0 0 18.392 36h.27a2.512 2.512 0 0 0 2.012-.996.878.878 0 1 1 1.398 1.065 4.26 4.26 0 0 1-2.002 1.452 4.246 4.246 0 0 1-1.409.237Zm-2.8-4.28h.001-.001Zm-.002-.03s0 .001 0 0c0 .001 0 0 0 0Zm0-.002c0 .002 0 .002 0 0 0 .002 0 .002 0 0Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M14.983 34.4a.88.88 0 0 1-.88-.88v-2.182a.88.88 0 0 1 1.759 0v2.182c0 .486-.395.88-.88.88Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M17.019 49.746H15.72c-1.086 0-1.77-.347-2.494-.715-.855-.433-1.825-.926-3.985-1.097h-2.06a2.534 2.534 0 0 1-2.53-2.53v-3.398c0-5.163 4.2-9.363 9.363-9.363h.967a.88.88 0 0 1 .002 1.758h-.969c-4.193 0-7.605 3.412-7.605 7.605v3.398c0 .426.346.772.772.772h2.094c.023 0 .045.001.067.003 2.532.196 3.773.826 4.678 1.286.623.315 1.033.525 1.698.525h1.298a.877.877 0 1 1 .002 1.757ZM11.502 23.503a.88.88 0 0 1-.833-.596c-.03-.09-.748-2.224-1.025-4.653-.4-3.518.316-5.829 2.131-6.867.68-.39 1.694-.36 2.36.067.458.292.886.689 1.23 1.008.113.106.215.2.303.276.091.076.208.118.324.112.218-.01.79-.071 1.407-.402.026-.014.052-.027.079-.039 2.574-1.065 6.155-.199 6.305-.16a.804.804 0 0 1 .141.048c.12.052 1.191.542 2.025 1.621a.88.88 0 0 1-1.392 1.076 3.984 3.984 0 0 0-1.279-1.056c-.504-.112-3.24-.663-5.094.082a5.172 5.172 0 0 1-2.107.586 2.223 2.223 0 0 1-1.55-.53c-.11-.095-.23-.204-.362-.328-.289-.27-.652-.606-.979-.814-.12-.078-.423-.088-.537-.023-2.153 1.232-1.25 6.207-.548 8.676 7.037-1.726 8.223-5.84 8.234-5.883a.88.88 0 0 1 1.254-.55 8.88 8.88 0 0 1 2.474 1.876.88.88 0 0 1-1.3 1.186 6.76 6.76 0 0 0-1.17-1.014c-.87 1.644-3.293 4.786-9.478 6.188-.15.034-.291.065-.436.094a.81.81 0 0 1-.178.02Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M31.443 33.822h-.182a8.31 8.31 0 0 1-4.468-1.298 8.304 8.304 0 0 1-3.865-7.035v-3.656a.88.88 0 0 1 1.758 0v3.657a6.554 6.554 0 0 0 3.052 5.553 6.553 6.553 0 0 0 3.525 1.023h.181a6.56 6.56 0 0 0 4.907-2.201 6.558 6.558 0 0 0 1.669-4.375V21.4a.88.88 0 0 1 1.758 0v4.09c0 2.05-.75 4.018-2.114 5.544a8.278 8.278 0 0 1-1.939 1.607 8.343 8.343 0 0 1-4.282 1.181Z"
      fill={color || "#111928"}
    />
    <path
      d="M23.332 22.814a.878.878 0 0 1-.879-.89c.001-.147.05-3.632.55-5.48a.881.881 0 0 1 .853-.67c.485 0 .88.392.88.877v.004c0 .08-.01.157-.031.233-.285 1.034-.406 2.788-.457 3.934 7.752-1.976 9.121-6.483 9.177-6.68a.884.884 0 0 1 1.254-.545c.961.502 1.81 1.121 2.522 1.842a8.864 8.864 0 0 1 1.387 1.824c-.04-.573-.079-1.198-.098-1.843a.88.88 0 0 1 1.758-.053c.024.76.073 1.474.122 2.166.126 1.781.224 3.188-.26 4.155-.17.349-.42.631-.738.834a.879.879 0 0 1-1.35-.793c0-.012.005-.09.002-.224l-.003-.07a7.26 7.26 0 0 0-2.072-4.76 7.999 7.999 0 0 0-1.254-1.03c-.917 1.8-3.597 5.438-10.692 7.045-.157.037-.323.072-.49.107a.991.991 0 0 1-.181.017ZM35.271 35.114h-.035a.88.88 0 0 1-.845-.878l.001-2.352a.88.88 0 0 1 1.758 0v2.302c0 .04-.001.079-.004.117a.877.877 0 0 1-.875.812ZM24.38 35.295h-.005a.88.88 0 1 1 .005 0ZM27.264 35.116a.878.878 0 0 1-.876-.813c-.003-.038-.003-.076-.003-.114v-2.405a.88.88 0 0 1 1.758 0l.002 2.453a.88.88 0 0 1-.847.88c-.012-.002-.022 0-.034 0Zm.88-.92Z"
      fill={color || "#111928"}
    />
    <path
      d="M35.271 35.126a.88.88 0 0 1-.877-.88c0-.406.28-.767.676-.86a.877.877 0 0 1 1.083.855.896.896 0 0 1-.26.629.888.888 0 0 1-.622.256Zm-.621-1.5.614.614-.614-.614Z"
      fill={color || "#111928"}
    />
    <path
      d="M34.477 52.463h-6.379c-1.198 0-1.96-.385-2.766-.794-.932-.473-1.988-1.008-4.245-1.226l-.03-.003c-.09-.01-.18-.018-.274-.025h-2.331a2.728 2.728 0 0 1-2.11-.988 2.75 2.75 0 0 1-.636-1.755v-3.838a10.463 10.463 0 0 1 5.222-9.056 10.327 10.327 0 0 1 3.287-1.225.876.876 0 0 1 1.032.741 5.405 5.405 0 0 0 5.324 4.524h1.546a5.405 5.405 0 0 0 5.142-3.671 8.866 8.866 0 0 0-.592-.02h-1.396a.88.88 0 0 1 0-1.759h1.396c.613 0 1.23.054 1.833.161a.88.88 0 0 1 .715 1.005 7.153 7.153 0 0 1-7.097 6.043h-1.55a7.162 7.162 0 0 1-6.846-5.1 8.612 8.612 0 0 0-1.906.819c-.057.033-.113.065-.166.098-.46.28-.89.599-1.279.949a8.671 8.671 0 0 0-1.645 1.978 8.666 8.666 0 0 0-1.26 4.513v3.838a.994.994 0 0 0 .288.696.98.98 0 0 0 .7.289h2.365a18.113 18.113 0 0 1 .406.032l.03.003c2.588.249 3.908.918 4.874 1.408.718.363 1.191.605 1.97.605h6.38c.78 0 1.255-.242 1.975-.607 1.012-.514 2.4-1.217 5.221-1.44a.88.88 0 0 1 .138 1.752c-2.475.195-3.585.758-4.564 1.256-.809.41-1.57.797-2.772.797Z"
      fill={color || "#111928"}
    />
    <path
      d="M42.304 36.958a.879.879 0 0 1-.88-.88c0-.019 0-.04.002-.058.067-1.037.09-2.164.063-3.35-.003-.125-.006-.253-.012-.379-.09-3.072-.479-6.542-1.19-10.622-.053-.315-.11-.631-.168-.948l-.006-.032a7.645 7.645 0 0 1-.029-.166c-.368-1.997-.856-3.536-1.491-4.707a7.75 7.75 0 0 0-.65-1.01 6.332 6.332 0 0 0-.426-.496 5.56 5.56 0 0 0-.84-.727c-1.202-.85-2.817-1.27-4.938-1.286-2.41 0-4.31.888-5.805 2.716a10.746 10.746 0 0 0-1.283 2.012c-.003.007-.008.014-.01.022-.149.297-.29.605-.417.914-.999 2.414-1.603 5.586-1.792 9.428l-.004.054c-.193 1.73-.31 3.15-.366 4.469a42.035 42.035 0 0 0 0 3.696.88.88 0 0 1-1.757.078 43.215 43.215 0 0 1 0-3.85c.059-1.353.176-2.802.372-4.561.2-4.03.847-7.39 1.922-9.987.141-.337.293-.672.453-.997l.015-.031c.44-.885.948-1.68 1.506-2.36 1.822-2.23 4.236-3.361 7.172-3.361 2.498.019 4.44.545 5.945 1.608.394.279.765.598 1.098.95.18.189.358.397.543.636.29.377.56.795.804 1.243.727 1.338 1.276 3.052 1.678 5.238l.003.018c.007.047.016.094.025.142l.006.032c.058.321.117.645.171.964.724 4.161 1.12 7.714 1.213 10.858.006.128.009.267.012.406a39.857 39.857 0 0 1-.066 3.497.872.872 0 0 1-.873.827Z"
      fill={color || "#111928"}
    />
    <path
      d="M34.477 52.463h-6.379c-1.198 0-1.96-.386-2.766-.794-.932-.474-1.988-1.008-4.245-1.227l-.03-.003-.274-.025h-2.331a2.729 2.729 0 0 1-2.11-.987 2.75 2.75 0 0 1-.636-1.755v-3.839a10.435 10.435 0 0 1 3.49-7.8 10.241 10.241 0 0 1 1.73-1.256 10.32 10.32 0 0 1 3.288-1.224l.026-.005c.223-.042.433-.075.64-.098.41-.053.849-.08 1.29-.08h.96a.878.878 0 0 1 1.014.869v.005c0 .22-.08.44-.244.611a.872.872 0 0 1-.612.273H26.168a8.69 8.69 0 0 0-1.626.152c-.012.001-.022.004-.034.006a8.668 8.668 0 0 0-2.699 1.01l-.165.097c-.46.282-.89.6-1.279.95a8.671 8.671 0 0 0-1.645 1.978 8.666 8.666 0 0 0-1.26 4.512v3.839a.995.995 0 0 0 .288.696.98.98 0 0 0 .7.288h2.365l.068.003.338.03.03.003c2.588.249 3.91.918 4.874 1.407.718.364 1.191.606 1.97.606h6.38c.78 0 1.255-.242 1.975-.607 1.012-.514 2.4-1.217 5.221-1.44.024-.002.046-.003.07-.003h2.644a.973.973 0 0 0 .684-.275.967.967 0 0 0 .305-.71v-3.838a8.653 8.653 0 0 0-2.654-6.256 8.703 8.703 0 0 0-4.526-2.316.878.878 0 1 1 .305-1.732c1.549.272 3.043.908 4.322 1.838.396.289.774.607 1.123.947a10.406 10.406 0 0 1 3.188 7.519v3.838c0 .756-.3 1.458-.846 1.978-.25.242-.543.432-.867.563a2.722 2.722 0 0 1-1.034.202h-2.61c-2.45.198-3.555.758-4.53 1.253-.803.41-1.565.797-2.766.797Z"
      fill={color || "#111928"}
    />
    <path
      d="M32.262 42.916a1.054 1.054 0 1 1-2.107 0 1.054 1.054 0 0 1 2.107 0ZM32.262 45.964a1.053 1.053 0 1 1-2.105.001 1.053 1.053 0 0 1 2.105-.001ZM32.233 49.26a1.053 1.053 0 1 0-2.05-.485 1.053 1.053 0 0 0 2.05.484Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default StaffRoomIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const AttachedGarage = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M58.9 29.799h-6.79a.88.88 0 0 1 0-1.758h5.911v-3.546L31.488 7.138 4.986 24.62l-.006 3.42h5.97a.88.88 0 0 1 0 1.759H4.1a.88.88 0 0 1-.88-.88l.008-4.774c0-.294.149-.57.395-.732L31.002 5.352a.879.879 0 0 1 .965-.001l27.416 17.933c.247.162.398.44.398.735v4.9a.882.882 0 0 1-.88.88Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M52.59 25.41 31.98 11.928a.879.879 0 0 0-.965 0L10.578 25.412a.88.88 0 0 0-.395.734v30.769a.88.88 0 0 0 .879.879h4.798a.88.88 0 0 0 .879-.88V29.8h29.52v27.116c0 .485.395.88.88.88h4.97a.88.88 0 0 0 .878-.88v-30.77a.874.874 0 0 0-.397-.735Zm-1.36 30.625h-3.21V28.92a.88.88 0 0 0-.88-.88H15.86a.88.88 0 0 0-.88.88v27.116h-3.04V26.618l19.562-12.903L51.23 26.62v29.414Z"
      fill={color || "#111928"}
    />
    <path
      d="M37.365 25.5h-11.73a.88.88 0 0 1-.88-.879v-3.91a.88.88 0 0 1 .88-.88h11.73a.88.88 0 0 1 .879.88v3.91a.879.879 0 0 1-.88.88Zm-10.851-1.758h9.972V21.59h-9.972v2.152ZM36.408 45.575a.878.878 0 0 1-.854-1.087 1.867 1.867 0 0 0-1.81-2.313 1.867 1.867 0 0 0-1.812 2.313.878.878 0 1 1-1.707.418 3.627 3.627 0 0 1 3.52-4.488 3.627 3.627 0 0 1 3.519 4.488.881.881 0 0 1-.856.67Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M32.34 38.295h-1.683a.88.88 0 0 1 0-1.758h1.684a.88.88 0 0 1 0 1.758Z"
      fill={color || "#111928"}
    />
    <path
      d="m44.617 52.939-1.077-6.846c-.208-1.318-1.307-2.274-2.615-2.274h-.015l-1.428-7.773c-.271-1.474-1.487-2.543-2.891-2.543H26.407c-1.404 0-2.62 1.07-2.89 2.543l-1.43 7.773h-.014c-1.308 0-2.407.956-2.615 2.273l-1.077 6.847a.88.88 0 0 0 .869 1.015h1.023v2.96a.88.88 0 0 0 .879.879h5.277a.88.88 0 0 0 .88-.88v-2.959h8.381v2.96a.88.88 0 0 0 .88.879h5.276a.88.88 0 0 0 .88-.88v-2.959h1.022a.882.882 0 0 0 .869-1.015Zm-19.37-16.576c.117-.64.606-1.103 1.162-1.103h10.184c.555 0 1.044.464 1.161 1.103l1.37 7.454H23.877l1.37-7.454Zm-4.05 10.002c.072-.457.441-.79.878-.79h18.851c.437 0 .806.333.878.79l.917 5.831H20.277l.92-5.831Zm4.354 9.67h-3.519v-2.08h3.52v2.08Zm15.418 0h-3.52v-2.08h3.518v2.08h.002Z"
      fill={color || "#111928"}
    />
    <path
      d="M39.375 49.758h-2.302a.88.88 0 0 1 0-1.758h2.302a.88.88 0 0 1 0 1.758ZM25.927 49.758h-2.302a.88.88 0 0 1 0-1.758h2.302a.88.88 0 0 1 0 1.758Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default AttachedGarage;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const ReceptionIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M33.571 49.068h-5.82a.755.755 0 0 1-.752-.67l-1.182-10.355h-7.453a3.882 3.882 0 0 1-2.765-1.148 3.882 3.882 0 0 1-1.14-2.77l.003-1.337a.755.755 0 0 1 .756-.754h.003c.417.001.755.34.753.759l-.004 1.338a2.38 2.38 0 0 0 .7 1.697 2.378 2.378 0 0 0 1.694.703h8.129c.384 0 .708.289.75.67l1.183 10.355h4.432c-.267-.726-1.552-1.323-1.568-1.33a.76.76 0 0 1-.451-.625l-.922-10.364v-.023a1.747 1.747 0 0 0-1.732-1.641l-5.836-.027a.756.756 0 0 1 .003-1.512h.004l5.836.027a3.261 3.261 0 0 1 3.232 3.053l.882 9.926c1.174.629 2.588 1.901 1.972 3.537a.753.753 0 0 1-.707.491ZM24.699 19.118c-.123 0-.246-.016-.37-.05l-2.221-.595a3.014 3.014 0 0 1-2.128-3.687l.537-2.005a2.993 2.993 0 0 1 1.403-1.825 2.986 2.986 0 0 1 2.283-.303l.696.187a2.992 2.992 0 0 1 1.826 1.403c.403.699.51 1.509.303 2.284l-.946 3.53a1.436 1.436 0 0 1-1.383 1.061Zm-2.2-2.105 2.143.574.925-3.451c.103-.384.049-.787-.153-1.135a1.486 1.486 0 0 0-.907-.7l-.696-.186a1.487 1.487 0 0 0-1.135.152c-.348.2-.596.523-.699.908l-.537 2.004c-.214.798.261 1.62 1.059 1.834ZM51.675 32.256H31.5a.757.757 0 0 1-.756-.756V28.53a.757.757 0 0 1 1.512 0v2.213H50.92v-1.851H33.292a.757.757 0 0 1 0-1.512h18.383c.417 0 .756.339.756.756V31.5a.757.757 0 0 1-.756.756Z"
      fill={color || "#111928"}
    />
    <path
      d="M19.952 49.068h-8.627a.757.757 0 0 1 0-1.512h8.627a2.396 2.396 0 0 0 2.394-2.394v-7.119h-7.871a3.911 3.911 0 0 1-3.906-3.906v-3.504a24.5 24.5 0 0 0-.475-4.794l-1.192-5.96a.756.756 0 0 1 1.483-.296l1.192 5.96a26.05 26.05 0 0 1 .504 5.09v3.504a2.396 2.396 0 0 0 2.394 2.394h8.627c.417 0 .756.34.756.756v7.875a3.91 3.91 0 0 1-3.906 3.906ZM43.757 28.893h-5.532a.758.758 0 0 1-.716-1l2.874-8.407a.756.756 0 0 1 .716-.512h5.532a.758.758 0 0 1 .716 1l-2.874 8.407a.755.755 0 0 1-.716.512Zm-4.475-1.512h3.935l2.357-6.895H41.64l-2.357 6.895Z"
      fill={color || "#111928"}
    />
    <path
      d="M22.351 33.546h-7.133a.756.756 0 0 1-.74-.906l2.06-10.193a4.265 4.265 0 0 1 4.168-3.408 4.25 4.25 0 0 1 2.743 1.002.757.757 0 0 1-.978 1.155 2.693 2.693 0 0 0-1.765-.645 2.75 2.75 0 0 0-2.687 2.196l-1.877 9.287h5.59l1.327-6.556a.756.756 0 0 1 1.482.3l-1.449 7.162a.755.755 0 0 1-.74.606Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M31.956 29.393a.714.714 0 0 1-.171-.02l-5.986-1.395a.767.767 0 0 1-.28-.13l-5.05-3.754a.111.111 0 0 0-.015-.01l-.598-.445a.756.756 0 1 1 .904-1.213l.587.437.014.01 4.934 3.668 5.694 1.326c.341-.056.626-.168.845-.335.473-.355.62-.937.662-1.357l-6.161-1.43a.748.748 0 0 1-.281-.13l-4.659-3.477a.756.756 0 1 1 .908-1.21l.11.083 4.418 3.299 6.592 1.53c.31.073.54.329.579.642.011.087.25 2.127-1.257 3.26-.46.348-1.033.566-1.7.646a.71.71 0 0 1-.089.005Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M49.155 49.068h-9.063a.757.757 0 0 1 0-1.512h9.063c.973 0 1.764-.791 1.764-1.764V31.5a.757.757 0 0 1 1.512 0v14.292a3.28 3.28 0 0 1-3.276 3.276Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default ReceptionIcon;

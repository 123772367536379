import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const PrinterRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M54.37 30.079H8.63a.882.882 0 0 1-.88-.88c0-.483.396-.878.88-.878h45.74c.484 0 .88.395.88.879a.882.882 0 0 1-.88.879Z"
      fill={color || "#111928"}
    />
    <path
      d="M22.02 48.246h-5.67c-1.582 0-2.959-.967-3.413-2.403L7.941 30.386a4.004 4.004 0 0 1-.19-1.172c0-.79.234-1.538.673-2.183l2.564-3.78a9.745 9.745 0 0 1 8.03-4.249h3.003c.483 0 .879.396.879.88 0 .483-.396.879-.88.879h-3.003a7.964 7.964 0 0 0-6.578 3.472l-2.564 3.765a2.14 2.14 0 0 0-.366 1.187c0 .22.029.425.102.645l4.996 15.457c.22.703.938 1.186 1.744 1.186h5.67c.483 0 .879.396.879.88a.894.894 0 0 1-.88.893ZM46.65 48.246h-5.67a.882.882 0 0 1-.88-.879c0-.483.396-.879.88-.879h5.67c.805 0 1.523-.483 1.743-1.187l4.996-15.457c.058-.205.102-.425.102-.644a2.03 2.03 0 0 0-.366-1.187l-2.564-3.765a7.928 7.928 0 0 0-6.578-3.473h-3.004a.882.882 0 0 1-.879-.879c0-.483.396-.879.88-.879h3.003a9.67 9.67 0 0 1 8.029 4.249l2.563 3.765c.44.645.674 1.407.674 2.183 0 .396-.058.792-.19 1.172l-4.996 15.457c-.454 1.436-1.832 2.403-3.414 2.403Z"
      fill={color || "#111928"}
    />
    <path
      d="M40.98 25.186H22.02a.882.882 0 0 1-.878-.88V8.161c0-.484.395-.88.879-.88h18.958c.484 0 .88.396.88.88v16.145c0 .484-.396.88-.88.88ZM22.9 23.427h17.2V9.04H22.9v14.387ZM40.98 55.718H22.02a.882.882 0 0 1-.878-.879V38.694c0-.484.395-.88.879-.88h18.958c.484 0 .88.396.88.88v16.145c0 .498-.396.88-.88.88ZM22.9 53.96h17.2V39.573H22.9V53.96Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M43.236 25.185H19.764a.882.882 0 0 1-.879-.879c0-.483.396-.879.88-.879H43.22c.483 0 .879.396.879.88a.87.87 0 0 1-.864.878ZM43.236 39.573H19.764a.882.882 0 0 1-.879-.88c0-.483.396-.878.88-.878H43.22c.483 0 .879.395.879.879a.87.87 0 0 1-.864.879Z"
      fill={color || "#111928"}
    />
    <path
      d="M36.467 45.316h-9.948a.882.882 0 0 1-.88-.879c0-.483.396-.879.88-.879h9.948c.483 0 .879.396.879.88a.882.882 0 0 1-.88.878ZM36.467 49.99h-9.948a.882.882 0 0 1-.88-.879c0-.483.396-.88.88-.88h9.948c.483 0 .879.396.879.88 0 .483-.396.88-.88.88ZM14.93 34.708a.882.882 0 0 1-.88-.879v-.014c0-.484.396-.88.88-.88.483 0 .878.396.878.88v.014c0 .484-.395.88-.879.88ZM19.266 34.708a.882.882 0 0 1-.879-.879v-.014c0-.484.396-.88.88-.88.483 0 .878.396.878.88v.014c0 .484-.395.88-.879.88Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default PrinterRoomIcon;

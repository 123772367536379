import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const YogaStudio = ({ height = 63, width = 63 }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 45 45" fill="none">
    <path
      d="M39.255 41.285H5.745a.63.63 0 0 1-.628-.628.63.63 0 0 1 .628-.628h33.51a.63.63 0 0 1 .627.628.63.63 0 0 1-.627.628ZM25.294 27.314h-5.588a.63.63 0 0 1-.628-.628.63.63 0 0 1 .628-.628h5.588a.63.63 0 0 1 .628.628.63.63 0 0 1-.628.628Z"
      fill="#111928"
    />
    <path
      d="M7.294 41.285a.605.605 0 0 1-.544-.325 3.587 3.587 0 0 1-.272-2.857 3.64 3.64 0 0 1 1.905-2.145l6.896-3.202 3.893-6.384a.627.627 0 0 1 .858-.21.627.627 0 0 1 .21.859l-3.998 6.551a.61.61 0 0 1-.272.24l-7.075 3.276a2.328 2.328 0 0 0-1.245 1.403c-.147.44-.157.9-.031 1.34l10.559-4.637 6.593-8.874a.622.622 0 0 1 .879-.126c.283.21.335.597.126.88l-6.698 9.01a.663.663 0 0 1-.251.198l-11.282 4.95a.72.72 0 0 1-.25.053Z"
      fill="#111928"
    />
    <path
      d="M11.553 39.412a.623.623 0 0 1-.575-.388L9.7 36a.626.626 0 0 1 .335-.827c.324-.136.69.01.827.335l1.277 3.025a.626.626 0 0 1-.335.826.6.6 0 0 1-.252.053ZM37.162 41.285h-5.735a.641.641 0 0 1-.628-.555l-.953-7.723-7.294-2.281a.623.623 0 0 1-.408-.785.623.623 0 0 1 .785-.408l7.681 2.396c.23.073.409.283.44.523l.931 7.577h3.705l-1.674-1.737a.703.703 0 0 1-.178-.377l-.618-6.54a2.216 2.216 0 0 0-1.496-1.884l-6.635-2.208a.633.633 0 0 1-.398-.796.624.624 0 0 1 .796-.397l6.634 2.208a3.443 3.443 0 0 1 2.345 2.95l.596 6.322 2.543 2.637c.178.178.22.45.126.68a.598.598 0 0 1-.565.398Z"
      fill="#111928"
    />
    <path
      d="M30.966 37.643a.635.635 0 0 1-.628-.575.632.632 0 0 1 .576-.68l3.36-.273a.632.632 0 0 1 .68.576.632.632 0 0 1-.576.68l-3.36.272c-.01-.01-.03 0-.052 0ZM20.198 15.269a2.17 2.17 0 0 1-2.167-2.167 2.17 2.17 0 0 1 2.167-2.166.63.63 0 0 1 .628.628.63.63 0 0 1-.628.628.911.911 0 0 0 0 1.82.63.63 0 0 1 .628.629.63.63 0 0 1-.628.628ZM25.964 17.686h-.042l-2.01-.146a.631.631 0 0 1-.585-.67.631.631 0 0 1 .67-.586l1.517.105c.094-.335.188-.859.188-1.487v-.041c.063-1.057-.115-1.79-.523-2.125-.429-.356-1.067-.199-1.067-.199a.63.63 0 1 1-.335-1.214c.052-.01 1.245-.335 2.187.43.743.606 1.078 1.674.994 3.17 0 1.371-.408 2.324-.418 2.365a.607.607 0 0 1-.576.398ZM23.902 8.78h-2.71a.63.63 0 0 1-.628-.628.63.63 0 0 1 .628-.628h2.71a.63.63 0 0 1 .628.628.63.63 0 0 1-.628.628Z"
      fill="#111928"
    />
    <path
      d="M25.294 27.314h-5.588a.63.63 0 0 1-.628-.628v-5.243c-.084-1.078.827-3.035 1.14-3.673l.262-13.437a.628.628 0 0 1 .628-.618 3.43 3.43 0 0 1 3.422 3.422v10.58c.419.252 1.068.775 1.56 1.707.962 1.82.89 4.343-.2 7.472a.643.643 0 0 1-.596.418Zm-4.96-1.256h4.51c1.957-6.038-1.036-7.325-1.172-7.378a.625.625 0 0 1-.398-.586V7.137c0-.983-.659-1.81-1.548-2.072l-.241 12.883a.61.61 0 0 1-.063.272c-.481.952-1.13 2.553-1.078 3.15v4.688h-.01Z"
      fill="#111928"
    />
  </svg>
);

export default YogaStudio;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const DiningIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M50.492 21.676H48.09c-.228-1.418-1.01-2.939-2.128-4.062-1.08-1.086-2.386-1.744-3.818-1.934V14.26a.88.88 0 0 0-1.758 0v1.419c-1.431.19-2.738.848-3.818 1.934-1.118 1.123-1.902 2.646-2.127 4.062h-2.403a.88.88 0 0 0-.88.88v.241a4.039 4.039 0 0 0 4.036 4.035h12.142a4.039 4.039 0 0 0 4.035-4.035v-.242a.878.878 0 0 0-.879-.879Zm-12.677-2.823c.67-.672 1.812-1.475 3.45-1.475 1.638 0 2.78.803 3.45 1.475.874.878 1.385 1.938 1.586 2.823H36.229c.203-.886.714-1.945 1.586-2.823Zm9.522 6.221H35.194a2.28 2.28 0 0 1-2.186-1.64h16.514a2.28 2.28 0 0 1-2.185 1.64Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M18.213 31.712h-8.189v-2.234c0-1.56-.074-3.138-.222-4.691l-.875-9.198a.876.876 0 0 0-.958-.791.877.877 0 0 0-.792.958l.875 9.198c.142 1.497.214 3.02.214 4.524v3.484a4.83 4.83 0 0 0 4.823 4.823h8.558v6.352a3.58 3.58 0 0 1-3.575 3.575H9.145a.88.88 0 0 0 0 1.758h8.927a5.34 5.34 0 0 0 5.333-5.333v-7.23a5.199 5.199 0 0 0-5.192-5.195Zm-8.143 1.759h8.141a3.439 3.439 0 0 1 3.32 2.555H13.09a3.067 3.067 0 0 1-3.02-2.556ZM54.948 25.074H27.582a.88.88 0 0 0-.879.879v4.21c0 .486.394.88.88.88h3.932L29.289 48.63a.88.88 0 1 0 1.743.221l2.254-17.807H49.26l2.254 17.807a.88.88 0 1 0 1.743-.22l-2.225-17.588h3.915a.88.88 0 0 0 .879-.88v-4.21a.879.879 0 0 0-.88-.879Zm-.88 4.21H28.462v-2.452H54.07v2.453Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default DiningIcon;

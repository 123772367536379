import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const AtticIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.434 19.397c-1.313-1.383-3.393-1.36-4.68.052L10.971 38.961a1.838 1.838 0 0 0 0 2.456 1.67 1.67 0 0 0 2.489 0l16.405-17.999a1.715 1.715 0 0 1 2.533-.027L49.561 41.47a1.672 1.672 0 0 0 2.492-.053 1.84 1.84 0 0 0-.049-2.459l-3.927-4.137V22.227c0-1.013-.79-1.834-1.763-1.834h-3.162c-.973 0-1.762.821-1.762 1.834v5.55l-7.956-8.38Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default AtticIcon;

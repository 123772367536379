import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const ConferenceRoomIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M42.242 49.14h-.036a.88.88 0 0 1-.843-.914c.002-.025.002-.051.002-.075V36.955a.88.88 0 0 1 1.758 0V48.15c0 .048 0 .1-.003.148a.88.88 0 0 1-.877.841ZM31.52 59.749c-6.396 0-11.6-5.204-11.6-11.6V29.289a.88.88 0 0 1 1.758 0V48.15c0 5.427 4.416 9.84 9.843 9.84 4.246 0 8.108-2.837 9.391-6.901a.879.879 0 1 1 1.678.529 11.756 11.756 0 0 1-4.148 5.826 11.485 11.485 0 0 1-6.921 2.305ZM42.243 34.672a.88.88 0 0 1-.88-.88V14.854c0-5.427-4.415-9.842-9.842-9.842a9.77 9.77 0 0 0-6.95 2.892 9.912 9.912 0 0 0-2.477 4.124.88.88 0 0 1-1.683-.508c.55-1.822 1.559-3.5 2.915-4.859a11.513 11.513 0 0 1 8.193-3.408c6.397 0 11.601 5.205 11.601 11.601v18.941a.876.876 0 0 1-.877.878ZM20.799 27.01a.88.88 0 0 1-.88-.88V14.866a.88.88 0 0 1 1.76 0V26.13c0 .487-.395.88-.88.88Z"
      fill={color || "#111928"}
    />
    <path
      d="M44.896 52.652c-.04 0-.078-.003-.116-.007l-5.923-.793a.86.86 0 0 1-.219-.059c-1.163-.48-1.664-1.238-1.88-1.788-.502-1.285.05-2.544.115-2.683a.878.878 0 0 1 .905-.503l6.334.789 3.582-2.578a.879.879 0 0 1 1.026 1.427l-3.86 2.778a.878.878 0 0 1-.622.159l-5.92-.736c-.023.221-.014.478.082.715.126.315.39.561.811.753l5.541.741 3.922-1.897a7.535 7.535 0 0 0 3.543-6.363 7.535 7.535 0 0 0-3.543-6.363l-3.922-1.897-5.54.741c-.42.19-.685.438-.813.753a1.507 1.507 0 0 0-.08.715l5.919-.735c.22-.028.442.029.621.158l3.86 2.778a.88.88 0 0 1-1.025 1.427l-3.582-2.577-6.334.786a.878.878 0 0 1-.905-.502c-.065-.14-.617-1.398-.114-2.683.215-.55.716-1.31 1.88-1.789a.872.872 0 0 1 .218-.058l5.923-.793a.867.867 0 0 1 .5.08l4.199 2.033.076.04a9.304 9.304 0 0 1 4.419 7.886 9.305 9.305 0 0 1-4.42 7.885c-.024.015-.049.03-.075.041l-4.2 2.032a.89.89 0 0 1-.383.087Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M49.29 46.778a4.187 4.187 0 0 1-4.18-4.181 4.186 4.186 0 0 1 4.18-4.181 4.186 4.186 0 0 1 4.182 4.181 4.186 4.186 0 0 1-4.181 4.181Zm0-6.603a2.426 2.426 0 0 0-2.422 2.423 2.426 2.426 0 0 0 2.423 2.424 2.426 2.426 0 0 0 2.423-2.424 2.426 2.426 0 0 0-2.423-2.423Z"
      fill={color || "#111928"}
    />
    <path
      d="M17.967 30.545a.866.866 0 0 1-.382-.088l-4.2-2.032a1.284 1.284 0 0 1-.076-.041 9.305 9.305 0 0 1-4.418-7.886 9.304 9.304 0 0 1 4.418-7.885.766.766 0 0 1 .077-.04l4.199-2.033a.89.89 0 0 1 .5-.08l5.923.792c.074.01.15.03.218.059 1.163.48 1.664 1.238 1.88 1.789.502 1.284-.05 2.543-.115 2.682a.878.878 0 0 1-.905.503l-6.334-.789-3.582 2.578a.878.878 0 1 1-1.025-1.427l3.86-2.778a.877.877 0 0 1 .621-.159l5.92.736a1.507 1.507 0 0 0-.081-.715c-.126-.315-.391-.561-.81-.753l-5.542-.741-3.922 1.898a7.535 7.535 0 0 0-3.542 6.363 7.533 7.533 0 0 0 3.542 6.363l3.922 1.898 5.542-.742c.419-.19.684-.438.81-.753a1.51 1.51 0 0 0 .08-.715l-5.919.736a.876.876 0 0 1-.62-.158l-3.861-2.778a.88.88 0 0 1 1.025-1.427l3.582 2.577 6.334-.788a.878.878 0 0 1 .905.502c.065.14.617 1.4.115 2.683-.216.55-.717 1.31-1.88 1.789a.873.873 0 0 1-.218.058l-5.924.793a.783.783 0 0 1-.117.009Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M13.573 24.689a4.187 4.187 0 0 1-4.181-4.182 4.186 4.186 0 0 1 4.181-4.181 4.186 4.186 0 0 1 4.181 4.181 4.187 4.187 0 0 1-4.181 4.182Zm0-6.605a2.426 2.426 0 0 0-2.423 2.423 2.426 2.426 0 0 0 2.423 2.423 2.426 2.426 0 0 0 2.423-2.423 2.427 2.427 0 0 0-2.423-2.423ZM14.672 49.686h-.573a6.752 6.752 0 0 1-6.744-6.744v-.674a6.752 6.752 0 0 1 6.744-6.744h.573a3.223 3.223 0 0 1 3.219 3.22v7.723a3.221 3.221 0 0 1-3.22 3.22ZM14.1 37.284a4.992 4.992 0 0 0-4.985 4.986v.674A4.992 4.992 0 0 0 14.1 47.93h.573c.806 0 1.46-.655 1.46-1.461v-7.724c0-.806-.654-1.461-1.46-1.461H14.1ZM48.9 27.58h-.573a3.223 3.223 0 0 1-3.22-3.22v-7.724a3.223 3.223 0 0 1 3.22-3.218h.573a6.752 6.752 0 0 1 6.744 6.743v.674A6.752 6.752 0 0 1 48.9 27.58Zm-.572-12.404c-.806 0-1.46.655-1.46 1.46v7.724c0 .806.654 1.461 1.46 1.461h.573a4.992 4.992 0 0 0 4.986-4.986v-.674a4.992 4.992 0 0 0-4.986-4.985h-.573Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default ConferenceRoomIcon;

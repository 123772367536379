import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const OfficeIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M48.52 30.575H27.93c-1.23 0-2.23-1-2.23-2.23V14.48c0-1.23 1-2.229 2.23-2.229h20.591c1.23 0 2.23 1 2.23 2.229v13.865a2.235 2.235 0 0 1-2.23 2.23ZM27.93 13.762a.718.718 0 0 0-.718.717v13.865c0 .395.322.718.718.718h20.591a.719.719 0 0 0 .718-.718V14.48a.718.718 0 0 0-.718-.717h-20.59v-.001Z"
      fill={color || "#111928"}
    />
    <path
      d="M43.86 35.623H32.59a.755.755 0 0 1-.334-1.434c1.056-.52 2.258-3.105 2.768-4.612a.756.756 0 0 1 1.433.485c-.111.328-.866 2.489-2 4.05h7.538c-1.134-1.561-1.89-3.72-2-4.05a.757.757 0 0 1 1.433-.485c.511 1.507 1.715 4.093 2.768 4.612a.756.756 0 0 1-.337 1.434Z"
      fill={color || "#111928"}
    />
    <path
      d="M38.23 28.247a1.138 1.138 0 0 1-1.138-1.134 1.13 1.13 0 0 1 1.129-1.134h.011a1.134 1.134 0 1 1-.001 2.268ZM18.05 35.619a4.124 4.124 0 0 1-4.12-4.12v-3.362c0-.417.34-.756.757-.756h6.724c.417 0 .756.339.756.756V31.5a4.122 4.122 0 0 1-4.117 4.119Zm-2.606-6.726V31.5a2.61 2.61 0 0 0 2.607 2.607 2.61 2.61 0 0 0 2.607-2.607v-2.607h-5.214ZM18.05 49.068h-3.363a.757.757 0 0 1-.756-.756v-8.407a.757.757 0 0 1 1.512 0v7.651h1.85v-7.65a.757.757 0 0 1 1.513 0v8.406a.755.755 0 0 1-.756.756ZM48.312 49.068H44.95a.757.757 0 0 1-.756-.756v-8.407a.757.757 0 0 1 1.512 0v7.651h1.85v-7.65a.757.757 0 0 1 1.512 0v8.406a.756.756 0 0 1-.756.756Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M51.675 40.663h-40.35a.757.757 0 0 1-.756-.756v-5.044c0-.417.339-.756.756-.756h40.35c.417 0 .756.339.756.756v5.044a.757.757 0 0 1-.756.756ZM12.081 39.15h38.838v-3.532H12.081v3.532ZM19.48 25.539a.757.757 0 0 1-.756-.756c0-.63.216-.99.359-1.23.104-.175.145-.24.145-.452 0-.21-.04-.277-.145-.453-.144-.239-.36-.6-.36-1.23a.757.757 0 0 1 1.513 0c0 .212.039.278.145.453.143.24.359.6.359 1.23s-.217.99-.36 1.23c-.104.175-.144.24-.144.452a.757.757 0 0 1-.756.756ZM16.117 25.546a.757.757 0 0 1-.756-.756c0-.63.217-.99.359-1.23.104-.175.145-.24.145-.452 0-.21-.04-.277-.145-.452-.144-.24-.36-.6-.36-1.23a.757.757 0 0 1 1.513 0c0 .212.039.277.145.452.143.24.359.6.359 1.23s-.217.99-.36 1.23c-.104.175-.144.24-.144.452a.757.757 0 0 1-.756.756ZM49.919 25.532H26.52a.757.757 0 0 1 0-1.512h23.398a.757.757 0 0 1 0 1.512Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default OfficeIcon;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const FunctionalStudio = ({ height = 63, width = 63 }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 45 45" fill="none">
    <path
      d="M17.907 11.236c-.172 0-.344-.035-.507-.105l-1.935-.831a2.811 2.811 0 0 1-1.51-1.553 2.809 2.809 0 0 1 .025-2.165l.73-1.699a2.811 2.811 0 0 1 1.553-1.51 2.809 2.809 0 0 1 2.165.025l.516.222a2.812 2.812 0 0 1 1.51 1.553 2.808 2.808 0 0 1-.025 2.165l-1.339 3.117a1.279 1.279 0 0 1-.705.686 1.25 1.25 0 0 1-.478.095Zm-.588-6.81c-.2 0-.398.039-.589.115-.392.157-.699.454-.864.84l-.73 1.698c-.165.384-.169.812-.013 1.204.156.393.454.7.84.865l1.933.83c.002.002.01.005.023 0s.016-.013.017-.015l1.338-3.117c.166-.384.17-.812.014-1.204a1.56 1.56 0 0 0-.84-.864l-.515-.222a1.512 1.512 0 0 0-.614-.13ZM7.107 21.773a.628.628 0 0 1-.628-.628v-.001a.628.628 0 1 1 .628.629Z"
      fill="#111928"
    />
    <path
      d="M18.785 39.748c-.298-.945-1.285-1.657-1.876-2.01l1.39-6.444a.626.626 0 0 0-.073-.452l-4.353-7.34-.014-.024-.002-.004a.03.03 0 0 1-.006-.007v-.002a.626.626 0 0 0-.518-.273h-.017c-.015 0-.03.002-.044.003a1.522 1.522 0 0 0-.024.003l-.036.005a.335.335 0 0 0-.03.008l-.029.007c-.011.003-.023.007-.034.012l-.023.008a.564.564 0 0 0-.056.026l-.036.021-.015.008-.004.004a.47.47 0 0 0-.027.02c-.009.006-.017.01-.024.017l-.016.014a.762.762 0 0 0-.03.028l-.007.007-.034.038a.021.021 0 0 0-.004.006c-.01.014-.021.027-.03.042a.169.169 0 0 1-.01.015.302.302 0 0 0-.034.063c-.005.008-.01.015-.012.024a.512.512 0 0 0-.02.054c-.004.013-.008.026-.01.039-.003.006-.004.012-.006.019l-.006.036-.003.024-.003.033-.001.031-.002.013.049 7.616L7.96 37.28a.628.628 0 0 0-.028.76c.253.357.978 1.604.786 2.315l-3.925-2.52 4.797-7.14a.629.629 0 0 0 .082-.527c-.018-.062-1.808-6.218-1.937-9.056a.628.628 0 0 0-1.255.058c.122 2.66 1.541 7.791 1.904 9.066l-4.991 7.431a.625.625 0 0 0 .182.878l4.957 3.18a.624.624 0 0 0 .716-.026c1.29-.968.68-2.8-.026-3.98l3.283-4.001-.85 7.136a.628.628 0 0 0 .623.702h5.8a.627.627 0 0 0 .547-.32c.262-.466.318-.982.159-1.488Zm-5.8.558.986-8.283a.651.651 0 0 0-.001-.142.649.649 0 0 0 .04-.223l-.034-5.523 3.044 5.134-1.436 6.662a.63.63 0 0 0 .34.698c.546.265 1.48.905 1.665 1.497.02.061.029.12.03.18h-4.634ZM42.086 28.321c-4.913 0-6.662-3.36-7.737-8.42 1.57-4.06 3.606-7.743 7.707-7.548a.628.628 0 0 0 .06-1.255c-4.218-.2-6.566 2.95-8.197 6.567l-.051-.283c-.73-4.046-1.419-7.868-4.732-7.868-1.905 0-3.375 1.195-4.253 3.457a.628.628 0 0 0 1.17.455c.684-1.762 1.721-2.655 3.083-2.655 2.112 0 2.729 2.582 3.496 6.835.129.716.263 1.454.413 2.193-.37.98-.718 1.985-1.056 2.958-1.526 4.41-2.968 8.578-5.893 8.578-2.9 0-4.115-4.205-4.503-6.012a.628.628 0 1 0-1.228.264c.452 2.105 1.915 7.004 5.73 7.004 3.82 0 5.404-4.578 7.081-9.423.13-.375.262-.755.396-1.134.428 1.542 1.043 3.16 2.007 4.465 1.51 2.044 3.7 3.081 6.508 3.081a.628.628 0 1 0 0-1.259Z"
      fill="#111928"
    />
    <path
      d="M27.715 12.441a.627.627 0 0 0-.652-.25l-6.237 1.415-5.698-2.109a4.023 4.023 0 0 0-5.434 2.031l-.291.677a.627.627 0 0 0-.071.165l-2.803 6.526a.628.628 0 0 0 .33.826l6.215 2.667a.627.627 0 0 0 .837-.323l.307-.713 4.78 2.838a.619.619 0 0 0 .203.078c.25.047.491.071.721.071.61 0 1.148-.165 1.61-.494 1.043-.744 1.245-2.06 1.252-2.115a.626.626 0 0 0-.301-.63l-5.502-3.26-.671-1.361.68-1.584 3.633 1.208a.637.637 0 0 0 .336.017l5.695-1.287a.64.64 0 0 0 .201-.084c.781-.502 1.253-1.144 1.403-1.91.246-1.259-.511-2.353-.543-2.399Zm-14.71 10.553L7.93 20.816l2.073-4.828 3.337 6.227-.335.78Zm1.22-8.741a.63.63 0 0 0-1.127.556l1.958 3.968.006.012.876 1.775c.053.11.138.2.243.263l5.251 3.111c-.104.282-.297.651-.636.89-.328.232-.74.306-1.257.226l-4.77-2.833-.174-.323-3.956-7.386.209-.486a2.766 2.766 0 0 1 3.764-1.384 2.777 2.777 0 0 1 1.536 2.474 2.723 2.723 0 0 1-.227 1.089l-.34.794-1.356-2.746Zm12.799.351c-.08.394-.328.73-.759 1.028l-5.411 1.223-3.496-1.161c.029-.192.043-.385.043-.579 0-.542-.114-1.07-.319-1.556l3.483 1.29a.63.63 0 0 0 .357.022l5.952-1.35c.117.275.233.677.15 1.083Z"
      fill="#111928"
    />
  </svg>
);

export default FunctionalStudio;

import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const ClassStudio = ({ height = 63, width = 63 }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 45 45">
    <path
      d="M30.232 43.043h-5.205a.628.628 0 0 1-.608-.472L20.9 28.854a.628.628 0 0 1 1.216-.312l3.399 13.245h4.225c-.073-.193-.355-.62-1.443-1.232-.147-.083-.394-.222-.421-.558a.534.534 0 0 1-.002-.042c-.873-4.052-1.746-8.104-2.617-12.156a.628.628 0 1 1 1.227-.265l2.588 12.016c.547.317 1.671 1.033 1.905 1.993.102.421.026.842-.22 1.217a.627.627 0 0 1-.524.283Zm-2.124-2.614c0 .001 0 .001 0 0 0 .001 0 .001 0 0Z"
      fill="#111928"
    />
    <path
      d="M25.639 27.225c-.29 0-.55-.201-.614-.495l-.316-1.462-.015-.071a.629.629 0 1 1 1.231-.245l.012.052c.104.487.209.973.315 1.46a.629.629 0 0 1-.613.76ZM17.986 43.043h-5.204a.629.629 0 0 1-.524-.282 1.52 1.52 0 0 1-.22-1.219c.233-.96 1.357-1.675 1.905-1.991 1.048-4.873 2.098-9.746 3.149-14.619a.628.628 0 1 1 1.227.265l-3.18 14.764v.022c-.02.347-.27.488-.42.572-1.09.613-1.372 1.039-1.444 1.233h4.226L20.9 28.542a.627.627 0 1 1 1.216.311l-3.52 13.72a.63.63 0 0 1-.61.47ZM28.712 16.45c-.271 0-.544-.085-.776-.261l-1.297-.985a2.835 2.835 0 0 1-.542-3.968l.754-.993a2.834 2.834 0 0 1 3.968-.54l.066.05a2.835 2.835 0 0 1 .542 3.967l-1.688 2.223a1.286 1.286 0 0 1-1.027.507ZM27.4 14.203l1.296.985c.013.01.034.006.044-.006l1.688-2.223a1.577 1.577 0 0 0-.301-2.207l-.066-.05a1.578 1.578 0 0 0-2.208.301l-.755.993a1.578 1.578 0 0 0 .302 2.207Z"
      fill="#111928"
    />
    <path
      d="M25.309 25.693h-7.605a.63.63 0 0 1-.62-.726l1.809-11.555a.628.628 0 0 1 1.241.195l-1.695 10.83h6.356l.758-3.786a.629.629 0 0 1 1.231.246l-.858 4.29a.63.63 0 0 1-.617.506Z"
      fill="#111928"
    />
    <path
      d="M22.868 15.2a.628.628 0 0 1-.536-.953l2.476-4.075c.14-.232.324-.429.546-.585l4.553-3.604c.389-.438.557-.866.516-1.307-.049-.528-.386-.984-.655-1.27l-6.029 4.673-.024.018a.71.71 0 0 0-.193.202l-3.461 5.515a.627.627 0 1 1-1.064-.668L22.46 7.63c.137-.218.312-.403.52-.552l6.436-4.99a.626.626 0 0 1 .758-.009c.056.041 1.357 1.019 1.499 2.468.08.819-.213 1.598-.87 2.317a.58.58 0 0 1-.074.07l-4.61 3.648-.034.025a.703.703 0 0 0-.204.216l-2.475 4.074a.629.629 0 0 1-.538.303Z"
      fill="#111928"
    />
    <path
      d="M27.195 29.39a.618.618 0 0 1-.2-.032c-.052-.018-1.284-.44-1.783-1.609-.327-.766-.264-1.639.187-2.594a.61.61 0 0 1 .112-.164l2.198-2.317-1.91-1.393a.628.628 0 1 1 .738-1.015l2.489 1.813a.627.627 0 0 1 .086.939l-2.618 2.76c-.256.578-.299 1.074-.127 1.476.154.361.455.603.692.746l4.266-4.378a1.499 1.499 0 0 0-.17-2.238c-7.248-5.47-8.376-6.162-8.548-6.243a.628.628 0 0 1 .535-1.137c.568.268 3.519 2.414 8.77 6.378a2.749 2.749 0 0 1 .312 4.116l-4.58 4.7a.622.622 0 0 1-.45.192Z"
      fill="#111928"
    />
  </svg>
);

export default ClassStudio;

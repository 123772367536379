import * as React from "react";
import { RoomIconSVGProps } from "../room-icon/RoomIcon";

const KitchenIcon = ({ width = 63, height = 63, color }: RoomIconSVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 63 63">
    <path
      d="M31.5 57.799H8.22a.882.882 0 0 1-.88-.88V33.274c0-.484.396-.88.88-.88H31.5c.484 0 .88.396.88.88V56.92c0 .483-.396.879-.88.879ZM9.098 56.041h21.523V34.15H9.098v21.89Z"
      fill={color || "#111928"}
    />
    <path
      d="M54.78 57.799H31.5a.882.882 0 0 1-.879-.88V6.08c0-.483.396-.879.88-.879h23.28c.483 0 .879.396.879.88v50.824a.885.885 0 0 1-.88.894Zm-22.4-1.758h21.522V6.959H32.379v49.082Z"
      fill={color || "#111928"}
    />
    <path
      d="M26.695 53.887H13.01a.882.882 0 0 1-.879-.879V41.272c0-.483.396-.879.88-.879h13.684c.483 0 .879.396.879.88v11.735a.882.882 0 0 1-.88.879Zm-12.806-1.758h11.927v-9.977H13.89v9.977ZM16.483 38.24H13.01a.882.882 0 0 1-.879-.88c0-.483.396-.879.88-.879h3.457c.484 0 .88.396.88.88a.87.87 0 0 1-.865.879Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M21.479 45.975H18.02a.882.882 0 0 1-.879-.879c0-.483.396-.879.88-.879h3.457c.483 0 .879.396.879.88a.882.882 0 0 1-.88.878ZM49.272 34.005a.882.882 0 0 1-.88-.879V29.67c0-.484.396-.88.88-.88.483 0 .879.396.879.88v3.457c0 .484-.396.88-.88.88ZM49.272 45.946a.882.882 0 0 1-.88-.879v-3.458c0-.483.396-.879.88-.879.483 0 .879.396.879.88v3.457a.872.872 0 0 1-.88.879ZM19.75 38.679c-.352 0-.689-.147-.938-.38a1.11 1.11 0 0 1-.161-.206c-.044-.073-.088-.147-.117-.234a1.236 1.236 0 0 1-.073-.25 1.609 1.609 0 0 1-.03-.263c0-.088.015-.176.03-.264.014-.088.043-.161.073-.249.029-.088.073-.161.117-.234a1.107 1.107 0 0 1 .366-.367c.073-.044.147-.088.235-.117a1.324 1.324 0 0 1 1.436.293c.058.059.117.132.16.205.044.074.088.147.118.235.03.073.058.16.073.249.015.088.03.175.03.263 0 .352-.147.689-.382.938-.249.25-.586.381-.937.381ZM23.06 38.679c-.35 0-.688-.147-.937-.38a1.106 1.106 0 0 1-.161-.206c-.044-.073-.088-.147-.117-.234a1.236 1.236 0 0 1-.073-.25 1.609 1.609 0 0 1-.03-.263c0-.088.015-.176.03-.264.014-.088.043-.161.073-.249.029-.088.073-.161.117-.234a1.102 1.102 0 0 1 .366-.367c.073-.044.147-.088.235-.117a1.324 1.324 0 0 1 1.436.293c.058.059.117.132.16.205.045.074.088.147.118.235.03.073.058.16.073.249.015.088.03.175.03.263 0 .352-.147.689-.381.938-.25.25-.587.381-.938.381ZM26.387 38.679c-.352 0-.689-.147-.938-.38a1.11 1.11 0 0 1-.16-.206c-.045-.073-.089-.147-.118-.234a1.236 1.236 0 0 1-.073-.25 1.609 1.609 0 0 1-.03-.263c0-.088.015-.176.03-.264.014-.088.044-.161.073-.249.03-.088.073-.161.117-.234a1.107 1.107 0 0 1 .366-.367c.074-.044.147-.088.235-.117a1.323 1.323 0 0 1 1.436.293c.058.059.117.132.16.205.045.074.089.147.118.235.03.073.059.16.073.249.015.088.03.175.03.263 0 .352-.147.689-.381.938-.25.25-.601.381-.938.381Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M54.78 38.357H31.5a.882.882 0 0 1-.879-.88c0-.483.396-.878.88-.878h23.28c.483 0 .879.395.879.879 0 .483-.396.879-.88.879ZM26.255 34.152a.882.882 0 0 1-.88-.88v-6.006a4.058 4.058 0 1 0-8.116 0c0 .483-.396.879-.879.879a.882.882 0 0 1-.879-.88 5.821 5.821 0 0 1 5.816-5.816 5.821 5.821 0 0 1 5.817 5.817v6.007c0 .483-.396.879-.88.879Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default KitchenIcon;
